import Urls from "./Urls";

const getExtension = (fileName) => {
    //gets fileName.name
    return fileName.split('.').pop().toLowerCase();   
}

const allowedImages = (fileName) => {
    //gets fileName.name
    let extension = fileName.split('.').pop().toLowerCase();
    if(extension === "jpg" || extension === "jpeg" || extension === "png") return true;
    else return false

}

const validateSelectedFile = (selectedFile) => {
    const MIN_FILE_SIZE = 10 // 10KB
//    const MAX_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 354 // 1MB

    if (!selectedFile) {
      return {"status": 0, "message": "File not selected."}
    }

    const fileSizeKiloBytes = selectedFile.size / 1024

    if(fileSizeKiloBytes < MIN_FILE_SIZE){
        return {"status": 0, "message": "File is too small."}
    }
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
        return {"status": 0, "message": "File is too large. It should not be more than 300KB."}
    }
    return {"status": 1, "message": "File OK."}
  }

  
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
        
  }

  const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350) => {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height
  
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

export default 
{
    getExtension,
    allowedImages,
    validateSelectedFile,
    convertBase64,
    resizeImage
}