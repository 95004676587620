import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Constants from '../utilities/Constants';



const Footer = () => {
    
    return (   
      <>
           
      {/*  <div className="footer-content">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6">
                <p className="mb-0 text-muted text-medium">{Constants.AppTitle()}</p>
              </div>
              <div className="col-sm-6 d-none d-sm-block">
                <ul className="breadcrumb pt-0 pe-0 mb-0 float-end">
                  <li className="breadcrumb-item mb-0 text-medium">
                    <a href="/terms_and_conditions" className="btn-link">Terms</a>
                  </li>
                  <li className="breadcrumb-item mb-0 text-medium">
                    <a href="/privacy" className="btn-link">Privacy</a>
                  </li>
                  <li className="breadcrumb-item mb-0 text-medium">
                    <a href="/faq" className="btn-link">FAQ</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        </>
    )
}

export default Footer;