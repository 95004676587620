import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import CartServices from '../services/CartServices';
import LocationServices from '../services/LocationServices';
import LoginServices from '../services/LoginServices';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import Greetings from '../utilities/Greetings';
import NigeriaStatesLgas from '../utilities/NigeriaStatesLgas';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import Randoms from '../utilities/Randoms';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const Signup = () => {

    const [pageName, setPageName] = React.useState("Sign Up");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [stateOfResidence, setStateOfResidence] = useState(null);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([])
    const [cityOfResidence, setCityOfResidence] = useState(null);
    const [pickUpAddress, setPickUpAddress] = useState(null);
    const [landMark, setLandMark] = useState(null);
    const [nearestBusStop, setNearestBusStop] = useState(null);
    const [note, setNote] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [dateAdded, setDateAdded] = useState(new Date().toLocaleString());
    const [orderId, setOrderId] = useState(Randoms.RandomStrings(10));
    const [userId, setUserId] = useState(null)

    const [enterUserData, setEnterUserData] = useState(true);
    const [enterAddress, setEnterAddress] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +cookies.token
      }
    }
    
    useEffect(() => {
      LogOut()
        PageBarTitle(pageName)
        setStates(NigeriaStatesLgas.States)
        
    }, []);

    const [vendorDetails, setVendorDetails] = useState([])
    const GetVendorDetails = () =>{
      var options_ = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token
        }
      }
      fetch(process.env.REACT_APP_MAIN_API + 'get_vendor_details_by_id', options_)
      .then((response) => response.json())
      .then((json) => {
          if (json.status) { 
            setVendorDetails(json.message)
          }else{
            //alert(json.message)
          window.location.href="/signup_vendor";
          }
      })
      .catch((error) => console.error(error))
      .finally(console.log("Done"))
    }
 

    const UserLogin = () => {
      setErrMsg(null)
      setLoading(true)
      let user = {
          email: email, 
          password: password
      }

      const postOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(user)
      };
      
fetch(process.env.REACT_APP_MAIN_API + 'login', postOptions)
          .then((response) => response.json())
          .then((json) => {
              if (json.status === true) {                  
                  setCookie("token", json.loginToken, {
                      path: "/"
                    });
                    setToken(cookies.token)

                      setPassword(null)
                      setErrMsg(null)
                      if(json.accountType === "Vendor") 
                        {
                          GetVendorDetails()
                        } 
                        if(CartServices.TotalItems() > 0){
                          window.location.href="/cart";
                      }
                    else{
                            window.location.href="/";
                        }                         
              }
              else {
                  setPassword(null)
                  setErrMsg(json.message)
                  setLoading(false)
              }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
  }

    const LogOut = async () =>{
      //let token = UserServices.getToken()
      if(token != "" && token !== null ){
        
      fetch(process.env.REACT_APP_MAIN_API +'logout', options)
      .then((response) => response.json())
      .then((json) => {
        //alert(json.message)
          if (json.status == true) {
            removeCookie('token',{path:'/'});
            localStorage.removeItem("loginToken")
          }
      })
      .catch((error) => console.error(error))
      .finally(() => 
      {
        
        removeCookie('token',{path:'/'});
            localStorage.removeItem("loginToken");
             }
            );    
            }
    }


    const GetLGAs = (stateName) => {
        setLgas(NigeriaStatesLgas.GetLgas(stateName))
      }
  
      const CheckOutOrder = () =>{
  
         PostAddress()
        
      }
  
      const PostUserdata = () => {
        if(password != confirmPassword){
          setErrMsg("Passwords do not match")
        }else{

          setLoading(true)
          let user = {
              firstname: firstName, 
              lastname: lastName, 
              email: email, 
              phone: phone, 
              account_type: accountType,
              password: password,
              date_added: dateAdded
          }
  
          const postOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(user)
          };
  
          fetch(process.env.REACT_APP_MAIN_API + 'signup', postOptions)
              .then((response) => response.json())
              .then((json) => {
                  if (json.status === true) {

                   // localStorage.setItem('loginToken', json.loginToken);
  
                      //Get User ID
                      setUserId(json.user_id)
  
                      setErrMsg(null)
                      setEnterUserData(false)
                      setEnterAddress(true)
                      setLoading(false)
                  }
                  else {
                    setErrMsg(json.message)                      
                      setLoading(false)
                  }
  
              })
              .catch((error) => console.error(error))
              .finally(() => setLoading(false));
            }
      }
  
      
  
      const PostAddress = () => {
          setLoading(true)
          let address = {
              user_id: userId,
              order_id: orderId,
              state_of_residence: stateOfResidence,
              city_of_residence: cityOfResidence,
              landmark: landMark,
              nearest_busstop: nearestBusStop,
              pickup_address: pickUpAddress,
              note: note,
              date_added: dateAdded
          }
  
          const postOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(address)
          };
  
          fetch(process.env.REACT_APP_MAIN_API + 'add_address', postOptions)
              .then((response) => response.json())
              .then((json) => {
                  if (json.status === true) {
                      setErrMsg(null)
                      UserLogin()
                      //setLoading(false)
                      //window.location.replace("/login") 
                  }
                  else {
  
                      setErrMsg(json.message)
                      setLoading(false)
                  }
  
              })
              .catch((error) => console.error(error))
              .finally(() => setLoading(false));
      }

      const UserData = 
      <>
      <div className="row g-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">First Name</label>
          <input className="form-control" 
          value={firstName} 
          onChange={e => setFirstName(e.target.value)} 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Last Name</label>
          <input className="form-control" 
          value={lastName} 
          onChange={e => setLastName(e.target.value)} 
          />
        </div>
      </div>
      <div className="row g-3">
      <div className="col-md-6 mb-3">
          <label className="form-label">Phone</label>
          <input className="form-control" 
          value={phone} 
          onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Email</label>
          <input className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
      </div>
      <div className="row g-3">
      <div className="col-md-6 mb-3">
          <label className="form-label">Password</label>
          <input className="form-control"
           type={"password"} 
          value={password} 
          onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Confirm Password</label>
          <input className="form-control" type={"password"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
        </div>
      </div>

      <div className="row g-3">
      <div className="col-md-6 mb-3">
      <label className="form-label">Account Type</label>
          <select className="form-control" onChange={e => setAccountType(e.target.value)}>
          <option value={""}>Select</option>
          <option value={"Customer"}>Customer</option>
          <option value={"Vendor"}>Vendor</option>
          </select>
      </div>
      </div>

<div className="col-md-12 mb-3">
      {loading === true? Constants.loader : 
        <button onClick={() => PostUserdata()} className="btn btn-icon btn-icon-end btn-lg btn-primary w-100" type="button">
        <span>Next</span> <i className='fas fa-arrow-right'></i>
      </button>
      }
      </div>
      
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

      
      </>
 

const NoteAndAddress = 
<>
<div className="row g-3">
        <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">State of residence</label>
            <select className="form-control" onChange={e => {setStateOfResidence(e.target.value); GetLGAs(e.target.value)}}>
            <option value={""}>Select State</option>
            {states.map(st =>
            <option value={st}>{st}</option>
            )}
            </select>
</div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">City of residence</label>
            <select onChange={e => setCityOfResidence(e.target.value)} className="form-control">
            <option value={""}>Select City</option>
            {lgas.map(lg =>
            <option value={lg}>{lg}</option>
            )}
            </select>
          </div>
        </div>
      </div>
      <div className='row g-3'>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Landmark/Bus stop</label>
            <input className="form-control" value={landMark} onChange={e => setLandMark(e.target.value)} />
          </div>
          </div>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Nearest bus stop</label>
            <input className="form-control" value={nearestBusStop} onChange={e => setNearestBusStop(e.target.value)} />
          </div>
          </div>
      </div>
      <div className="row g-3">
        <div className="col-12 mb-3">
        <label className="form-label">Location Address</label>
          <textarea className="form-control" onChange={e => setPickUpAddress(e.target.value)}>{pickUpAddress}</textarea>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12 mb-3">
          <label className="form-label">Note (Optional)</label>
          <textarea className="form-control" maxLength={500} onChange={e => setNote(e.target.value)}>{note}</textarea>
        </div>
      </div>
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

        {loading === true? Constants.loader : 
        <button onClick={() => CheckOutOrder()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
        <span>Complete Registration</span> <i className='fas fa-arrow-right'></i>
      </button>
      }
</>

const DeliveryPickUpAddress = 

<div className="col-12 col-lg order-1 order-lg-0">
<div className="card mb-5">
    <div className="card-body">
    <div className="row g-3">
       </div>
        
    {enterUserData === true? UserData : ''}
    {enterAddress === true? NoteAndAddress : ''}
                          
    </div>
    </div>
    </div>

    
    return (        
        <>
        <main>
        <div className='container'>
            <div className='row d-flex align-content-center'>
        <div className="px-5">
                <div className="">
                <a href="/">
                    {Constants.logo}
                  </a>
                </div>
                <div className="row mb-5">
                  <h2 className="cta-1 mb-0 text-primary">{Greetings()}</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Already have an account?  <Link to="/login">Login</Link>.</p>
                </div>
                <div>

                {DeliveryPickUpAddress} 

                </div>
              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default Signup;