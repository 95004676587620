const loader = <center>
    <img src="/img/loader.gif" style={{
    width: 40, height: 40
}} /></center>;

const logo = 
    <img src={require("../icons/icon.png")} style={{
    width: 80, height: 80
}} />;

const AppTitle = () => {
    return "eLaundry"
}

const AppSubTitle = () => {
    return "For better laundry service"
}

const Address = () => {
    return "Lagos, Nigeria"
}

const Phone = () => {
    return "+2349032323684"
}

const Email = () => {
    return "support@elaundry.ng"
}

const Website = () => {
    return "https://elaundry.ng/"
}

const GetOrderStatus = (num) => {
    let result = 'Pending pickup';
    if(num == 1){
        result = 'Picked up';
    }
    else if(num == 2){
        result = 'Laundry started';
    }
    else if(num == 3){
        result = 'Laundry Waiting Delivery';
    }
    else if(num == 4){
        result = 'Laundry Delivered';
    }
    else if(num == 5){
        result = 'Order Rejected';
    }

    return result;

}

const GetOrderProgress = (num) => {
    let result = 10
    if(num == 1){
        result = 30;
    }
    else if(num == 2){
        result = 50;
    }
    else if(num == 3){
        result = 80;
    }
    else if(num == 4){
        result = 100;
    }
    else if(num == 5){
        result = 100;
    }

    return result;

}

export default {
    loader,
    logo,
    AppTitle,
    AppSubTitle,
    Address,
    Website,
    Phone,
    Email,
    GetOrderStatus,
    GetOrderProgress
}