import Urls from "./Urls";

const FetchImage = (fileName) => {

    let file = process.env.REACT_APP_MAIN_API + fileName+'/image';

    return file;
}

export default 
    FetchImage