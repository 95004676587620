import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CartServices from '../services/CartServices';
import GetSeletctedItemsById from '../services/GetSelectedItmesById';
import FetchImage from '../utilities/FetchImage';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import Footer from './Footer';
import Header from './Header';
import PageTile from './PageTitle';

const Cart = () => {

    const [pageName, setPageName] = React.useState("Cart");
    const [cartData, setCartData] = useState([]);

    const RemoveFromCart = (id) => {
        CartServices.DeleteSelectedCartItem(id)
        setCartData(CartServices.AllCarts)
    }

    const ReduceCartItem = (id, amount) => {
        CartServices.ReduceSelectedCartItem(id, amount)
        setCartData(CartServices.AllCarts)
    }

    const IncreaseCartItem = (id, amount) => {
        CartServices.IncreaseSelectedCartItem(id, amount)
        setCartData(CartServices.AllCarts)
    }

    const ClearCart = () => {
        CartServices.clearAllCart()
        setCartData(CartServices.AllCarts)
    }

    const GetCartItmes = () => {
        return (
        <div className="col-12 col-lg order-1 order-lg-0">
        <h2 className="small-title">Items</h2>
        {cartData.map(cart =>
            <div key={cart.id} className="mb-5">
              <div className="card mb-2">
                <div className="row g-0 sh-18 sh-md-14">
                  <div className="col-auto">
                    <img src={FetchImage(cart.img_url)} className="card-img card-img-horizontal h-100 sw-9 sw-sm-13 sw-md-15" alt={cart.title} />
                  </div>
                  <div className="col position-relative h-100">
                    <div className="card-body">
                      <div className="row h-100">
                        <div className="col-12 col-md-6 mb-2 mb-md-0 d-flex align-items-center">
                          <div className="pt-0 pb-0 pe-2">
                            <div className="h6 mb-0 clamp-line" data-line="1" 
                            style={{overflow: 'hidden', textOverflow: 'ellipsis',
                             WebkitLineClamp: 1}} >{cart.title}</div>
                            <div className="text-muted text-small">{cart.laundry_type}</div>
                            <div className="mb-0 sw-19">&#x20A6;{NumberFormat(cart.amount)}</div>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 pe-0 d-flex align-items-center">
                          <div className="input-group spinner sw-11" data-trigger="spinner">
                            <div className="input-group-text">
                              <button onClick={() => ReduceCartItem(cart.id, cart.amount)} type="button" className="spin-down single px-2 d-flex justify-content-center" data-spin="down">-</button>
                            </div>
                            <div type="text" className="form-control text-center px-0" data-rule="quantity">{GetSeletctedItemsById(cart.id)}</div>
                            <div className="input-group-text">
                              <button onClick={() => IncreaseCartItem(cart.id, cart.amount)} type="button" className="spin-up single px-2 d-flex justify-content-center" data-spin="up">+</button>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-3 d-flex justify-content-end justify-content-md-start align-items-center">
                          <div className="h6 mb-0">&#x20A6;{NumberFormat(cart.total_amount)}</div>
                        </div>
                      </div>
                    </div>
                    <button onClick={() => RemoveFromCart(cart.id)} className="btn btn-sm btn-icon btn-icon-only btn btn-foreground-alternate position-absolute t-2 e-2" type="button">
<i className='fas fa-times'></i>
                    </button>
                  </div>
                </div>
              </div>               
              </div>
              )}
              </div>
              )
    }

    const NoItemFound = () => {
        return (
        <div className="col-12 col-lg order-1 order-lg-0">
            <h2 className="small-title">Items</h2> 
            No Item found! </div>
        )
    }

    useEffect(() => {
        PageBarTitle(pageName)
        setCartData(CartServices.AllCarts)
    }, []);

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
           {PageTile(pageName)}
        </div>
        <div className="row">
           
{cartData == '' ? <NoItemFound/> : <GetCartItmes />}

              

            <div className="col-12 col-lg-auto order-0 order-lg-1">

              <h2 className="small-title">Summary</h2>
              <div className="card mb-5 w-100 sw-lg-35">
                <div className="card-body mb-n5">
                  <div className="mb-4">
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">ITEMS</p>
                      <p>
                        <span className="text-alternate">{CartServices.TotalItems()}</span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">TOTAL</p>
                      <p>
                        <span className="text-alternate">
                          <span className="text-small text-muted">&#x20A6;</span>
                          {NumberFormat(CartServices.TotalAmount())}
                        </span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">DELIVERY</p>
                      <p>
                        <span className="text-alternate">
                          <span className="text-small text-muted">&#x20A6;</span>
                          {NumberFormat(CartServices.Shipping())}
                        </span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">GRAND TOTAL</p>
                      <div className="cta-2">
                        <span>
                          <span className="text-small text-muted cta-2">&#x20A6;</span>
                          {NumberFormat(CartServices.GrandTotal())}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-5 w-100">
                      <a href={'/checkout'} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
                        <span>Checkout</span> <i className='fas fa-arrow-right'></i>
                      </a>
                    </div>                    
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column flex-sm-row justify-content-between mb-5 w-100">
                      <button onClick={ ()=>ClearCart()} className="btn btn-icon btn-icon-end btn-danger w-100" type="button">
                        <span>Cancel Order</span> <i className='fas fa-times'></i>
                      </button>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer/>
        </>
    )
}

export default Cart;