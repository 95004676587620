import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const PublicVendorDetails = () => {

    const { username } = useParams();

    const [pageName, setPageName] = React.useState("Vendor Details");
  
    useEffect(() => {
        PageBarTitle(pageName);
        GetVendorDetails();
    },[]);


      const [vendorDetails, setVendorDetails] = useState([])
      const GetVendorDetails = () =>{
        fetch(process.env.REACT_APP_MAIN_API + username+'/get_vendor_details_by_username')
        .then((response) => response.json())
        .then((json) => {
            if (json.status) { 
              setVendorDetails(json.message)
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
      }

      const copyToClipboard = (text) => {
        const el = text
        navigator.clipboard.writeText(text)
        alert("link copied")
      }



const VendorProfileCard = 
<div class="card-body">
            <div class="d-flex align-items-center flex-column mb-4">
              <div class="d-flex align-items-center flex-column">
              <div class="sw-13 position-relative mb-3">
                        <img src={vendorDetails.logo} 
                        class="img-fluid rounded-xl" alt="thumb" />
                      </div>
                      <div class="h5 mb-0">{vendorDetails.store_name} {vendorDetails.is_verified === 1? <i className="text-primary fas fa-check-circle"></i> : ''} </div>
                <div class="text-muted">Status: {vendorDetails.is_verified === 0? <span className="text-danger">Not Verified</span> : <span className="text-success"><b>Verified</b></span>}</div>
                <div class="text-muted">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="cs-icon cs-icon-pin me-1"><path d="M3.5 8.5C3.5 -0.191416 16.5 -0.191352 16.5 8.5C16.5 12.6585 12.8256 15.9341 11.0021 17.3037C10.4026 17.754 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.5Z"></path></svg>
                  <span class="align-middle">{vendorDetails.state}, {vendorDetails.city}</span>
                </div>
              </div>
            </div>
            <div class="nav flex-column" role="tablist">
            <a class="nav-link px-0 border-bottom border-separator-light" href={"tel:"+vendorDetails.phone} role="tab">
                <i className='fas fa-mobile me-3'></i>
                <span class="align-middle">{vendorDetails.phone}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-compass me-3'></i>
              <span class="align-middle">{vendorDetails.store_address}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-landmark me-3'></i>
              <span class="align-middle">{vendorDetails.landmark}</span>
              </a>
              <a onClick={() => copyToClipboard(Constants.Website()+vendorDetails.username+"/vendor")} data-bs-toggle="tab" class="nav-link px-0 border-bottom border-separator-light" href={Constants.Website()+vendorDetails.username+"/vendor"} role="tab">
                <i className='fas fa-link me-3'></i>
                <span class="align-middle">{Constants.Website()}{vendorDetails.username}/vendor</span>
              </a>
              </div>
          </div>

                
    
const ContentBox = 
<div className="row">

            <div class="col-12 col-xl-12 col-xxl-12">
              <h2 class="small-title">Vendor Details</h2>
              <div class="card mb-5">
               
                {vendorDetails != "" ? VendorProfileCard : <div class="card-body"><h4>Vendor not found or invisible. If you own this vendor account, kindly contact us for more information <a href={"mailto:"+Constants.Email()}>{Constants.Email()}</a></h4></div>}

                
              </div>
            </div>
            
</div>

const MainContent = 

<div className="col-12 col-lg">
        
    {ContentBox}

    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
                <div>

                {MainContent} 

              </div>
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default PublicVendorDetails;