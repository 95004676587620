import React, {Component, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import Urls from '../utilities/Urls';

const Header = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [userDetails, setUserDetails] = useState([]);
  const [isLogged, setIsLoggedIn] = useState(false);

  const [token, setToken] = useState(cookies.token);

  var options = {  
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }

 const IsLoggedIn = () =>{
  fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
  .then((response) => response.json())
  .then((json) => {
      if (json.status === true) { 
        setIsLoggedIn(true);
      }
      else
      {
        setIsLoggedIn(false)
      }
  })
  .catch((error) => console.error(error))
  .finally(console.log("Done"))
        
}

  const LogOut = async () =>{
    //let token = UserServices.getToken()
    if(token != "" && token !== null ){
      
    fetch(process.env.REACT_APP_MAIN_API +'logout', options)
    .then((response) => response.json())
    .then((json) => {
      //alert(json.message)
        if (json.status == true) {
          setIsLoggedIn(false)
          removeCookie('token',{path:'/'});
//          localStorage.removeItem("cart")
          localStorage.removeItem("loginToken")
          window.location.replace("/login")
        }
    })
    .catch((error) => console.error(error))
    .finally(() => 
    {
      setIsLoggedIn(false)
      removeCookie('token',{path:'/'});
   // localStorage.removeItem("cart");
          localStorage.removeItem("loginToken");
          window.location.replace("/"); }
          );    
          }
  }

  const GetUserDetails = async() =>{
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }
    
    fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options) 
    .then((response) => response.json())
    .then((json) => {
        if(json.status === true) {
          setUserDetails(json.message[0]);
          setIsLoggedIn(true);
        }
    })
    .catch((error) => console.error(error))
    .finally(() => console.log("User details"));    
          //}
  }

  useEffect( () => {
    //alert(isLogged)
    IsLoggedIn();
        GetUserDetails();
   },[]);

  return  <div id="nav" className="nav-container d-flex">
    <div className="nav-content d-flex">
    <div className="logo position-relative">
        <a href="/">
          <img src='/img/logo.png' style={{width: 50, height: 50}} />
        </a>
      </div>
      
      {token != null && isLogged ?
      <div className="user-container d-flex">
        <a href="#" className="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="profile fas fa-user-cog text-white fa-2x" />
          <div className="name">Hi, {userDetails.firstname}</div>
        </a>
        <div className="dropdown-menu dropdown-menu-end user-menu wide">
          <div className="row mb-1 ms-0 me-0">
            <div className="col-6 pe-1 ps-1">
              <ul className="list-unstyled">
              <li>
                  <a href="/profile">
                    <i data-cs-icon="user" className="me-2" data-cs-size="17"></i>
                    <span className="align-middle">Profile</span>
                  </a>
                </li>
                <li>
                  <a href="/change_password">
                    <i data-cs-icon="lock" className="me-2" data-cs-size="17"></i>
                    <span className="align-middle">Password</span>
                  </a><hr/>
                </li>
                {/**/}
                {userDetails.account_type === "Staff" ? 
        <li>
            <a href="#staff" data-href="#">
              <i className="icon fas fa-user-tie" data-cs-size="18"></i>
              <span className="label">Staff Menu</span>
            </a><hr/>
            <li>
                <a href="/staff/orders_staff">
                <i className="icon fas fa-arrow-right" data-cs-size="18"></i>
                  <span className="label">My Orders</span>
                </a>
              </li>
              <li>
                <a href="/staff/pending_orders_staff">
                <i className="icon fas fa-arrow-right" data-cs-size="18"></i>
                  <span className="label">Pending </span>
                </a>
              </li>
              <li>
                <a href="/staff/processing_orders_staff">
                <i className="icon fas fa-arrow-right" data-cs-size="18"></i>
                  <span className="label">Processing </span>
                </a>
              </li>
              <li>
                <a href="/staff/completed_orders_staff">
                <i className="icon fas fa-arrow-right" data-cs-size="18"></i>
                  <span className="text-left">Completed </span>
                </a>
              </li>
              <li>
                <a href="/staff/rejected_orders_staff">
                <i className="icon fas fa-arrow-right" data-cs-size="18"></i>
                  <span className="align-middle">Rejected </span>
                </a>
              </li><hr/>
          </li>
          : '' }
                {/* */}
                <li onClick={() => LogOut()}>
                  <a href="#">
                    <i data-cs-icon="logout" className="me-2" data-cs-size="17"></i>
                    <span className="align-middle">Log out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      : '' }

      <ul className="list-unstyled list-inline text-center menu-icons">
        <li className="list-inline-item">
          <a href="#" id="colorButton">
            <i data-cs-icon="light-on" className="light" data-cs-size="18"></i>
            <i data-cs-icon="light-off" className="dark" data-cs-size="18"></i>
          </a>
        </li>
       </ul>

      <div className="menu-container flex-grow-1">
        <ul id="menu" className="menu">
          
          {userDetails.account_type == "Staff" ? 
        <li>
            <a href="#staff" data-href="#">
              <i className="icon fas fa-user-tie" data-cs-size="18"></i>
              <span className="label">Staff</span>
            </a>
            <ul id="staff">
            <li>
                <Link to="/staff/orders_staff">
                  <span className="label">My Orders</span>
                </Link>
              </li>
              <li>
                <Link to="/staff/pending_orders_staff">
                  <span className="label">Pending Orders</span>
                </Link>
              </li>
              <li>
                <Link to="/staff/processing_orders_staff">
                  <span className="label">Processing Orders</span>
                </Link>
              </li>
              <li>
                <Link to="/staff/completed_orders_staff">
                  <span className="label">Completed Orders</span>
                </Link>
              </li>
              <li>
                <Link to="/staff/rejected_orders_staff">
                  <span className="label">Rejected Orders</span>
                </Link>
              </li>
            </ul>
          </li>
          : '' }

        <li>
            <a href="#laundry_service" data-href="#">
              <i data-cs-icon="basket" className="icon" data-cs-size="18"></i>
              <span className="label">Laundry Service </span>
            </a>
            <ul id="laundry_service">
            <li>
                <Link to="/wash_and_iron">
                  <span className="label">Wash and Iron</span>
                </Link>
              </li>
              <li>
                <Link to="/wash_only">
                  <span className="label">Wash only</span>
                </Link>
              </li>
              <li>
              <Link to="/iron_only">
                  <span className="label">Iron only</span>
                </Link>
              </li>
              <li>
              <Link to="/dry_cleaning">
                  <span className="label">Dry Cleaning</span>
                </Link>
              </li>
            </ul>
          </li>


{token == null && !isLogged ? <>
          <li>
            <Link to="/login"><i data-cs-icon="login" className="icon" data-cs-size="18"></i>
            <span className="label">Login</span>
            </Link>
            </li>
            <li>
            <Link to="/signup"><i data-cs-icon="user" className="icon" data-cs-size="18"></i>
            <span className="label">Create Account</span>
            </Link>
            </li>
            </>

          :
          
          <>
          <li>
            <Link to="/dashboard">
              <i data-cs-icon="shop" className="icon" data-cs-size="18"></i>
              <span className="label">Dashboard </span>
            </Link>
          </li>
           <li>
            <a href="#orders" data-href="#">
              <i data-cs-icon="cart" className="icon" data-cs-size="18"></i>
              <span className="label">Orders</span>
            </a>
            <ul id="orders">
           <li>
                <Link to="/pending_orders">
                  <span className="label">Pending</span>
                </Link>
              </li>
              <li>
                <Link to="/processing_orders">
                  <span className="label">Processing</span>
                </Link>
              </li>
              <li>
                <Link to="/completed_orders">
                  <span className="label">Completed</span>
                </Link>
              </li>
              <li>
                <Link to="/rejected_orders">
                  <span className="label">Rejected</span>
                </Link>
              </li>
            </ul>
          </li>
          </>

}

          <li>
            <Link to="/search">
              <i data-cs-icon="search" className="icon" data-cs-size="18"></i>
              <span className="label">Track Order</span>
            </Link>
          </li>
          <li>
            <a href="#resources" data-href="#">
              <i data-cs-icon="note" className="icon" data-cs-size="18"></i>
              <span className="label">Resources</span>
            </a>
            <ul id="resources">
            <li>
                <Link to="/faq">
                  <span className="label">FAQ</span>
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <span className="label">About</span>
                </Link>
              </li>
              <li>
              <Link to="/privacy">
                  <span className="label">Privacy Policy</span>
                </Link>
              </li>
              <li>
              <Link to="/terms_and_conditions">
                  <span className="label">Terms and Conditions</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">
              <i data-cs-icon="message" className="icon" data-cs-size="18"></i>
              <span className="label">Contact Us</span>
            </Link>
          </li>

          {token != null && isLogged ?
            <li onClick={() => LogOut()}>
            <Link to="#">
              <i data-cs-icon="power" className="icon" data-cs-size="18"></i>
              <span className="label">Log Out</span>
            </Link>
          </li>
          :
          ''
          }

        </ul>
      </div>

      <div className="mobile-buttons-container">
        <a href="#" id="mobileMenuButton" className="menu-button">
          <i data-cs-icon="menu"></i>
        </a>
      </div>

    </div>
    <div className="nav-shadow"></div>
  </div>
  
}


export default Header;