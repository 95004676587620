import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import CartServices from '../services/CartServices';
import LocationServices from '../services/LocationServices';
//import LoginServices from '../services/LoginServices';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import NigeriaStatesLgas from '../utilities/NigeriaStatesLgas';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import Randoms from '../utilities/Randoms';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';
import Footer from './Footer';
import Header from './Header';
import PageTile from './PageTitle';

const CheckOut = () => {

    const [pageName, setPageName] = React.useState("Checkout");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [cartData, setCartData] = useState([]);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [stateOfResidence, setStateOfResidence] = useState(null);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([])
    const [cityOfResidence, setCityOfResidence] = useState(null);
    const [pickUpAddress, setPickUpAddress] = useState(null);
    const [landMark, setLandMark] = useState(null);
    const [nearestBusStop, setNearestBusStop] = useState(null);
    const [note, setNote] = useState(null);
    const [accountType, setAccountType] = useState("");
    const [password, setPassword] = useState(Randoms.RandomStrings(6));
    const [dateAdded, setDateAdded] = useState(new Date().toLocaleString());
    const [orderId, setOrderId] = useState(Randoms.RandomStrings(10));
    const [userId, setUserId] = useState(null)

    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    const [enterUserData, setEnterUserData] = useState(true);
    const [enterAddress, setEnterAddress] = useState(false);

    const [isLogged, setIsLoggedIn] = useState(false)

    const [loggedUserData, setLoggedUserData] = useState([])

    

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +cookies.token
      }
    }
    useEffect(async() => {
        IsLoggedIn()
        GetUserDetails()
        PageBarTitle(pageName)
        setCartData(CartServices.AllCarts)
        setStates(NigeriaStatesLgas.States)
        
    }, []);

  
    const IsLoggedIn = async () =>{
      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                  setIsLoggedIn(true);
              }
              else{
                window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))           
      }

    const GetUserDetails = () =>{
      fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
              setLoggedUserData(json.message[0]);
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
    }

    const GetLGAs = (stateName) => {
      setLgas(NigeriaStatesLgas.GetLgas(stateName))
    }

    const CheckOutOrder = () =>{

      if(CartServices.TotalAmount() < 2000){
        setErrMsg("Sorry, minimum Order is 2,000 NGN. Add more itmes and try again.")
      }else{
        PostAddress()
      }
      
    }

    const PostUserdata = () => {
        //setLoading(true)
        if(isLogged == false){
setLoading(true)
let user = {
  firstname: firstName, 
  lastname: lastName, 
  email: email, 
  phone: phone, 
  account_type: accountType,
  password: password,
  date_added: dateAdded
}

const postOptions = {
  method: 'POST',
  headers: {
      'Content-Type': 'application/json',
  },
  body: JSON.stringify(user)
};

fetch(process.env.REACT_APP_MAIN_API + 'temp_signup', postOptions)
  .then((response) => response.json())
  .then((json) => {
      if (json.status == true) {
          //Get User ID
          setUserId(json.user_id)
          setErrMsg(null)
          setEnterUserData(false)
          setEnterAddress(true)
          setLoading(false)
      }
      else {

          setErrMsg(json.message)
          setLoading(false)
      }

  })
  .catch((error) => console.error(error))
  .finally(() => setLoading(false));
        }
        else{

          setLoading(true)
        fetch(process.env.REACT_APP_MAIN_API +'get_user_address_by_token', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                let x = json.message[0];
                setStateOfResidence(x.state_of_residence)
                setCityOfResidence(x.city_of_residence)
                setLandMark(x.landmark)
                setNearestBusStop(x.nearest_busstop)
                setPickUpAddress(x.pickup_address)
                setNote(x.note)
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))

          setUserId(loggedUserData.user_id)
          setErrMsg(null)
          setEnterUserData(false)
          setEnterAddress(true)
          setLoading(false)
        }
    }

    const PostOrder = () => {
        setLoading(true)
        let order = {
            vendor_id: 'Admin',
            user_id: userId,
            order_id: orderId,
            status: 0,
            total_items: CartServices.TotalItems(),
            total_amount: CartServices.TotalAmount(),
            order_details: cartData,
            date_added: dateAdded
        }

        const postOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token
            },
            body: JSON.stringify(order)
        };

        fetch(process.env.REACT_APP_MAIN_API + 'add_order', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) {
                    CartServices.clearAllCart()
                    setErrMsg(null)
                    //setLoading(false)
                    window.location.replace("/"+orderId+"/order_details")
                }
                else {

                    setErrMsg(json.message)
                    setLoading(false)
                }

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const PostAddress = () => {
      
        setLoading(true)
        let address = {
            user_id: userId,
            order_id: orderId,
            state_of_residence: stateOfResidence,
            city_of_residence: cityOfResidence,
            landmark: landMark,
            nearest_busstop: nearestBusStop,
            pickup_address: pickUpAddress,
            note: note,
            date_added: dateAdded
        }

        const postOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token
            },
            body: JSON.stringify(address)
        };

        fetch(process.env.REACT_APP_MAIN_API + 'add_address', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) {
                    setErrMsg(null)
                    setLoading(false)

                    PostOrder()
                }
                else {

                    setErrMsg(json.message)
                    setLoading(false)
                }

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const UserData = 
                    <>
                    <div className="row g-3">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">First Name</label>
                        <input className="form-control" 
                        value={firstName} 
                        onChange={e => setFirstName(e.target.value)} 
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Last Name</label>
                        <input className="form-control" 
                        value={lastName} 
                        onChange={e => setLastName(e.target.value)} 
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                    <div className="col-md-6 mb-3">
                        <label className="form-label">Phone</label>
                        <input className="form-control" 
                        value={phone} 
                        onChange={e => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Email</label>
                        <input className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
                      </div> 
                      <div className="col-md-6 mb-3">
      <label className="form-label">Account Type</label>
          <select className="form-control" onChange={e => setAccountType(e.target.value)}>
          <option value={""}>Select</option>
          <option value={"Customer"}>Customer</option>
          <option value={"Vendor"}>Vendor</option>
          </select>
      </div>
                    </div>

                    {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

                    {loading == true? Constants.loader : 
                      <button onClick={() => PostUserdata()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
                      <span>Next</span> <i className='fas fa-arrow-right'></i>
                    </button>
                    }
                    </>

const LoggedUserData = 
<>
<div className="row g-3">
  <div className="col-md-6 mb-3">
    <label className="form-label">First Name</label>
    <input className="form-control" 
    value={loggedUserData.firstname} readOnly
    />
  </div>
  <div className="col-md-6 mb-3">
    <label className="form-label">Last Name</label>
    <input className="form-control" 
    value={loggedUserData.lastname} 
    readOnly 
    />
  </div>
</div>
<div className="row g-3">
<div className="col-md-6 mb-3">
    <label className="form-label">Phone</label>
    <input className="form-control" 
    value={loggedUserData.phone} 
    readOnly
    />
  </div>
  <div className="col-md-6 mb-3">
    <label className="form-label">Email</label>
    <input className="form-control" value={loggedUserData.email} readOnly/>
  </div>
</div>
<div className="row g-3">
      <div className="col-md-6 mb-3">
      <label className="form-label">Account Type</label>
      <input className="form-control" value={loggedUserData.account_type} readOnly/>
      </div>
      </div>
{errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

{loading == true? Constants.loader : 
  <button onClick={() => PostUserdata()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
  <span>Next</span> <i className='fas fa-arrow-right'></i>
</button>
}
</>
               
    
    const NoteAndAddress = 
            <>
            <div className="row g-3">
                      <div className="col-md-6 mb-3">
                        <div className="w-100">
                          <label className="form-label">State of residence</label>
                          <select className="form-control" onChange={e => {setStateOfResidence(e.target.value); GetLGAs(e.target.value)}}>
                          <option value={stateOfResidence==null? "": stateOfResidence}>{stateOfResidence==null? "Select State": stateOfResidence}</option>
                          {states.map(st =>
                          <option value={st}>{st}</option>
                          )}
                          </select>
</div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="w-100">
                          <label className="form-label">City of residence</label>
                          <select onChange={e => setCityOfResidence(e.target.value)} className="form-control">
                          <option value={cityOfResidence==null? "": cityOfResidence}>{cityOfResidence==null? "Select City": cityOfResidence}</option>
                          {lgas.map(lg =>
                          <option value={lg}>{lg}</option>
                          )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='row g-3'>
                        <div className="col-md-6 mb-3">
                        <div className="w-100">
                          <label className="form-label">Landmark/Bus stop</label>
                          <input className="form-control" value={landMark} onChange={e => setLandMark(e.target.value)} />
                        </div>
                        </div>
                        <div className="col-md-6 mb-3">
                        <div className="w-100">
                          <label className="form-label">Nearest bus stop</label>
                          <input className="form-control" value={nearestBusStop} onChange={e => setNearestBusStop(e.target.value)} />
                        </div>
                        </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-12 mb-3">
                      <label className="form-label">Pick up address</label>
                        <textarea className="form-control" onChange={e => setPickUpAddress(e.target.value)} defaultValue={pickUpAddress}>{pickUpAddress}</textarea>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-12 mb-3">
                        <label className="form-label">Note (Optional)</label>
                        <textarea className="form-control" maxLength={500} onChange={e => setNote(e.target.value)} defaultValue={note}>{note}</textarea>
                      </div>
                    </div>
                    {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

                      {loading == true? Constants.loader : 
                      <button onClick={() => CheckOutOrder()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
                      <span>Checkout</span> <i className='fas fa-arrow-right'></i>
                    </button>
                    }
            </>

        const NotSignedIn = <div className="row g-3">
        <div className="col-12 mb-3">
      <span>Don't want to repeat filling the form below everytime? Click </span> 
      <Link to="/login">Login</Link> if you have an account or 
      <Link to={'/signup'}> Create New Account</Link>                  
            </div>
        </div>

    const DeliveryPickUpAddress = 
    
        <div className="col-12 col-lg order-1 order-lg-0">
        <h2 className="small-title">Delivery and Pickup Information</h2>
        <div className="card mb-5">
                  <div className="card-body">
                  
                  {isLogged == true ? "": NotSignedIn}
                      
                  {enterUserData == true && isLogged == false? UserData : ''}
                  {enterUserData == true && isLogged == true? LoggedUserData : ''}
                  {enterAddress == true? NoteAndAddress : ''}
                                        
                  </div>
                  </div>
                  </div>
              

    const NoItemFound = 
        <div className="col-12 col-lg order-1 order-lg-0">
            <h2 className="small-title">Items</h2> 
            No Item found! </div>
    
    const OrderSummary = 
    <div className="col-12 col-lg-auto order-0 order-lg-1">

              <h2 className="small-title">Summary</h2>
              <div className="card mb-5 w-100 sw-lg-35">
                <div className="card-body mb-n5">
                  <div className="mb-4">
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">ITEMS</p>
                      <p>
                        <span className="text-alternate">{CartServices.TotalItems()}</span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">TOTAL</p>
                      <p>
                        <span className="text-alternate">
                          <span className="text-small text-muted">&#x20A6;</span>
                          {NumberFormat(CartServices.TotalAmount())}
                        </span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">DELIVERY</p>
                      <p>
                        <span className="text-alternate">
                          <span className="text-small text-muted">&#x20A6;</span>
                          {NumberFormat(CartServices.Shipping())}
                        </span>
                      </p>
                    </div>
                    <div className="mb-2">
                      <p className="text-small text-muted mb-1">GRAND TOTAL</p>
                      <div className="cta-2">
                        <span>
                          <span className="text-small text-muted cta-2">&#x20A6;</span>
                          {NumberFormat(CartServices.GrandTotal())}
                        </span>
                      </div>
                    </div>
                  </div>
                    </div>
              </div>
            </div>
        

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
           {PageTile(pageName)}
        </div>
        <div className="row">
           
{isLogged ? DeliveryPickUpAddress : NoItemFound}              

{OrderSummary}
            
          </div>
        </main>
        <Footer/>
        </>
    )
}

export default CheckOut;