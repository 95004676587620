import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import Header from './Header';

const AboutUs = () => {

    const [pageName, setPageName] = React.useState("About Us");
    
    useEffect(() => {
        PageBarTitle(pageName)       
    }, []);

  
      

      
const Content = 
<div class="row g-0 sh-14 sh-md-10">
<div class="col">
      eLaundry is an online laundry service founded in 2020. It is available on the web and mobile applications. Our head office is located in {Constants.Address()}. For more information, you may see our <a href="/faq">FAQ</a> or <a href="/contact">click here</a> to contact us.

      <br/><br/>Mission<br/>
To make good laundry services easily accessible and affordable from anywhere in the world.


</div>
      </div>


const MainContent = 

<div className="col-12 col-lg order-1 order-lg-0">
    <div className="card-body">
        
    {Content}
                          
    </div>
    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
            <div className='row'>
        <div className="px-5">
                {MainContent} 
              </div>
        </div>   
        </div>     
        </main>
        </>
    )
}

export default AboutUs;