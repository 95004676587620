import { useEffect, useState } from "react";
import UserServices from "../services/UserServices";
import Urls from "../utilities/Urls";



const Logout = async () =>{

  const [token, setToken] = useState(localStorage.getItem('loginToken'));
    
    
    const LogOut = () =>{
        if(token == "" && token !== null ){
        fetch(process.env.REACT_APP_MAIN_API + token+'/logout')
        .then((response) => response.json())
        .then((json) => {
            if (json.status === true) {
              localStorage.removeItem("cart")
              localStorage.removeItem("loginToken")
              window.location.replace("/login")
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Log out"));    
              }
    }

    useEffect(()=>{
      LogOut();
  });

    return <h4>Logging Out!</h4>
  }

export default Logout;