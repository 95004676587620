import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Constants from '../utilities/Constants';
import Greetings from '../utilities/Greetings';
import NigeriaStatesLgas from '../utilities/NigeriaStatesLgas';
import PageBarTitle from '../utilities/PageBarTitle';
import Randoms from '../utilities/Randoms';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';
import Header from './Header';

const ContactUs = () => {

    const [pageName, setPageName] = React.useState("Contact Us");
    const [loading, setLoading] = useState(false);
    const [hideSendButton, setHideSendButton] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [succMsg, setSuccMsg] = useState(null);
    const [senderName, setSenderName] = useState("eLaundry Message");
    const [message, setMessage] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [subject, setSubject] = useState(null);
    const [dateAdded, setDateAdded] = useState(new Date().toLocaleString());
    
    useEffect(() => {
        PageBarTitle(pageName)       
    }, []);

      const SendMessage = () =>{
  
         PostRequest()
        
      }
  
      
      const PostRequest = () => {
          setLoading(true)
          let messageData = {
              sender_name: senderName,
              phone: phone,
              email: email,
              message: message,
              subject: subject,
              date_added: dateAdded
          }
  
          const postOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(messageData)
          };
  
          fetch(process.env.REACT_APP_MAIN_API + 'contact_us', postOptions)
              .then((response) => response.json())
              .then((json) => {
                  if (json.status === true) {
                      setErrMsg(null)
                      setLoading(false)
                      setHideSendButton(true)
                      setSuccMsg(json.message)

                  }
                  else {
  
                      setErrMsg(json.message)
                      setLoading(false)
                  }
  
              })
              .catch((error) => console.error(error))
              .finally(() => setLoading(false));
      }


const MessageBox = 
    <>
      <div className='row g-3'>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Email Address</label>
            <input className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          </div>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Phone Number</label>
            <input className="form-control" value={phone} onChange={e => setPhone(e.target.value)} />
          </div>
          </div>
      </div>
      <div className="row g-3">
        <div className="col-12 mb-3">
        <label className="form-label">Subject</label>
        <input className="form-control" value={subject} onChange={e => setSubject(e.target.value)} />
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12 mb-3">
          <label className="form-label">Message</label>
          <textarea className="form-control" maxLength={5000} onChange={e => setMessage(e.target.value)}>{message}</textarea>
        </div>
      </div>
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
      {succMsg !== null ? ShowMessage.SuccessMessage(succMsg): "" }

        {loading === true? Constants.loader :
        hideSendButton === false && loading !== true? 
        <button onClick={() => SendMessage()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
        <span>Send Message</span> <i className='fas fa-arrow-right'></i>
      </button> : ""
      }
</>

const MainContent = 

<div className="col-12 col-lg order-1 order-lg-0">
<div className="card mb-5">
    <div className="card-body">
    <div className="row g-3">
       </div>
        
    {MessageBox}
                          
    </div>
    </div>
    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
            <div className='row d-flex align-content-center'>
        <div className="px-5">
                <div>

                {MainContent} 

                </div>
              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default ContactUs;