const RandomStrings = (num) => {
    if(isNaN(num) === true) num = 8
    let randomString = require('random-string');   
        let x = randomString({
          length: num,
          numeric: true,
          letters: true,
          special: false,
          exclude: ['O', '0', 'o', '1', 'i', 'I', 'L', 'l']
        });
        return x;
}

const RandomNumbers = (num) => {
  if(isNaN(num) === true) num = 8
  let randomString = require('random-string');   
      let x = randomString({
        length: num,
        numeric: true,
        letters: false,
        special: false,
        exclude: ['O', '0', 'o', '1', 'i', 'I', 'L', 'l']
      });
      return x;
}

export default 
    {RandomStrings, RandomNumbers}