import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const ChangePassword = () => {

    const [pageName, setPageName] = React.useState("Change Password");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [loggedUserData, setLoggedUserData] = useState([])
    const [password, setPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [phone, setPhone] = useState(null);
    const [isLogged, setIsLoggedIn] = useState(false)
    const [userId, setUserId] = useState(null)
    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    useEffect(() => {
        PageBarTitle(pageName);
        IsLoggedIn()
        if(userId === null){
            GetUserDetails()
        }
    });

    const IsLoggedIn = async () =>{
      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                  //return true;
              }
              else{
                window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))           
      }

    const PostUserdata = () => {
        setLoading(true)
setLoading(true)
let user = {
    user_id: userId, 
    password: password, 
    new_password: newPassword, 
    confirm_password: confirmPassword
}

const postOptions = {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +token
  },
  body: JSON.stringify(user)
};

fetch(process.env.REACT_APP_MAIN_API + 'change_password', postOptions)
  .then((response) => response.json())
  .then((json) => {
      if (json.status === true) {
          //Get User ID
          alert(json.message)
          setPassword(null)
          setNewPassword(null)
          setConfirmPassword(null)
          setErrMsg(null)
          setLoading(false)
      }
      else {
          setErrMsg(json.message)
          setLoading(false)
      }

  })
  .catch((error) => console.error(error))
  .finally(() => setLoading(false));
        }
      


    const GetUserDetails = () =>{
        fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
          .then((response) => response.json())
          .then((json) => {
              if (json.status == true) { 
                setLoggedUserData(json.message[0]);
                if(loggedUserData != "")
                setUserId(loggedUserData.user_id)                       
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))
      }




                const profileCardForm = 
                <div class="card-body">
                <div className="row g-3">
  <div className="col-md-12 mb-3">
    <label className="form-label">Current Password</label>
    <input className="form-control" type="password"
    value={password}
    onChange={e => setPassword(e.target.value)}
    />
  </div>
  <div className="col-md-6 mb-3">
    <label className="form-label">New Password</label>
    <input className="form-control" type="password"
    value={newPassword} 
    onChange={e => setNewPassword(e.target.value)}
    />
  </div>

<div className="col-md-6 mb-3">
    <label className="form-label">Confirm Password</label>
    <input className="form-control" type="password"
    value={confirmPassword} 
   onChange={e => setConfirmPassword(e.target.value)}
    />
  </div>
</div>
{errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
{loading === true? Constants.loader : 
  <button onClick={() => PostUserdata()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
  <span>Update Password</span> 
</button>
}
                </div>
    
const ContentBox = 
<div className="row">
<div class="col-12 col-xl-12 col-xxl-12">
              <h2 class="small-title">Password Setting</h2>
              <div class="card mb-5">
               
                {profileCardForm}

                
              </div>
            </div>


            
</div>

const MainContent = 

<div className="col-12 col-lg">
        
    {ContentBox}

    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
                <div>

                {MainContent} 

              </div>
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default ChangePassword;