import { useCookies } from "react-cookie";
import Urls from "../utilities/Urls";



const GetUserId = (email) => {
    let userid = "";
    fetch(process.env.REACT_APP_MAIN_API + email+'/userid')
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                userid = json.message; 
                return userid;
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
}

const CheckIfLoggedIn = () =>{
    
    const [cookies, setCookie] = useCookies(["token"]);
    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': cookies.token
        }
      }
      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                  //return true;
              }
              else{
                window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))     

    } 


const LogOut = (token) => {
    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      }
    fetch(process.env.REACT_APP_MAIN_API +'logout', options)
    .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                localStorage.removeItem("loginToken");
                return true;
            }
            else{
                return false
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
}

const PickUpAddress = (token) => {
    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      }
    fetch(process.env.REACT_APP_MAIN_API +'get_user_address_by_token', options)
    .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                return json.message[0];
            }
            else{
                return null
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
}

const UserDetails = (token) =>{ 
    
    var options = {  
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      }
    fetch(process.env.REACT_APP_MAIN_API +'get_user_details_by_token', options)
    .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                return json.message[0];
            }
            else{
                return null
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
}

export default  {GetUserId, CheckIfLoggedIn, LogOut, PickUpAddress, UserDetails}