const wash_iron = require("../icons/wash_iron.png");
const wash = require("../icons/wash.png");
const iron = require("../icons/iron.png");
const dry_cleaning = require("../icons/dry_cleaning.png");
const faq = require("../icons/faq.png");
const contact = require("../icons/contact.png");
const about = require("../icons/about.png");
const track = require("../icons/track.png");

export default {
    wash_iron, 
    wash, 
    iron, 
    dry_cleaning,
    faq,
    contact,
    about,
    track
}