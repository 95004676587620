import React, { Component, useEffect, useState } from 'react';

const PageTile = (title) => {
  
    return (
         <div className="page-title-container">
            <div className="row">
              <div className="col-auto mb-3 mb-md-0 me-auto">
                <div className="w-auto sw-md-30">
                  <a href="#" className="muted-link pb-1 d-inline-block breadcrumb-back">
                    <span className="text-big align-middle">{title}</span>
                  </a>
                </div>
              </div>              
            </div>
          </div>
    )
}

export default PageTile;