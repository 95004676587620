const ErrorMessage = (msg) => {
       return(
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
        {msg}
      </div>
       );
}

const SuccessMessage = (msg) => {
      return(
            <div class="alert alert-success alert-dismissible fade show" role="alert">
            {msg}
          </div>
           );
}

export default 
    {ErrorMessage, SuccessMessage}