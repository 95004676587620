import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useParams } from "react-router-dom";
import CartServices from '../services/CartServices';
import Constants from '../utilities/Constants';
import FetchImage from '../utilities/FetchImage';
import NumberFormat from '../utilities/NumberFormat';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

const WashItems = () => {

  const [isLoading, setLoading] = useState(false);
  const [topWashListData, setTopWashListData] = useState([]);
  const [bottomWashListData, setBottomWashListData] = useState([]);
  const [topBottomWashListData, setTopBottomWashListData] = useState([]);
  const [beddingsOthersListData, setBeddingsOthersListData] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [selectedItmes, setSelectedItmes] = useState(0);
  const [newPrice, setNewPrice] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [cookies, setCookie] = useCookies(["token"]);
  const [token, setToken] = useState(cookies.token);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const [userDetails, setUserDetails] = useState([]);
  const GetUserDetails = async() =>{
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }
    
    fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options) 
    .then((response) => response.json())
    .then((json) => {
        if(json.status === true) {
          setUserDetails(json.message[0]);
        }
    })
    .catch((error) => console.error(error))
    .finally(() => console.log(" "));    
          //}
  }

  const editItem = (id) =>{
    setEditingId(id)
    let result = window.prompt("Enter New Price","");
    let text;
    if (result == null || result == "") 
    {
      setEditingId(null)
      text = "Action failed!";
      alert(text)
    }
    else 
    {
      setNewPrice(result)
      let data = {
        id: id, 
        price: result
    }
    
      const postOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' +token
        },
        body: JSON.stringify(data)
    };
    
        setLoading(true);
        fetch(process.env.REACT_APP_MAIN_API + 'admin/edit_laundry_price', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) { 
                  setLoading(false);
                  window.location.reload(false);
                 }
                 else{
                  alert(json.message)
                 }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
            setEditingId(null)
    
    }
    }
    
 
  useEffect(() => {
    GetUserDetails()
    TopListData();
  }, []);


  const getSeletedItems = (id) => {
  try {
    let cart = localStorage.getItem("cart")
    if(cart !== null){
      var newList = JSON.parse(cart)
      let check = newList.filter(item => item.id === id)
      if (check.length > 0) {
        //update cart for selected item
        let data = check[0]       
        let TotalItems = parseFloat(data.total_items)
        return TotalItems;
      }
      else {
        return 0;
      }
    }else {
      return 0;
    }
           
  } catch (error) {
    console.log(error)
    return 0;
  }
  }

  const createItemId = () => {
    try {
      let randString = localStorage.getItem("randString")
      if(randString === null){

        let randomString = require('random-string');   
        let x = randomString({
          length: 8,
          numeric: true,
          letters: true,
          special: false,
          exclude: ['O', '0']
        });
        setItemId(x)
        localStorage.setItem("randString", x)
      }
      else{
        setItemId(randString)
      }
      
    }catch(e){
      console.log(e) 
    }    
  }

  const clearItemId = () => {
    try {
      setItemId(null)
      localStorage.removeItem("randString")
    
    }catch(e){
      console.log(e) 
    }    
  }

  useEffect(() =>{
    createItemId()
    setSelectedItmes(CartServices.TotalItems)
  });

  const TopListData = () => {
    if(topWashListData == ""){
      setLoading(true);
      fetch(process.env.REACT_APP_MAIN_API + 'list/laundry/top')
          .then((response) => response.json())
          .then((json) => {
              //setData(json)
              if (json.status == true) { 
                setTopWashListData(json.wash); 
                setLoading(false); }
          })
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
    }
    
}

const BottomListData = () => {
  if(bottomWashListData == ""){
    setLoading(true);
    fetch(process.env.REACT_APP_MAIN_API + 'list/laundry/bottom')
        .then((response) => response.json())
        .then((json) => {
            //setData(json)
            if (json.status == true) { 
              setBottomWashListData(json.wash)
              setLoading(false); }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
  }
  
}

const TopBottomListData = () => {
  if(topBottomWashListData == ""){
    setLoading(true);

    fetch(process.env.REACT_APP_MAIN_API + 'list/laundry/top_bottom')
        .then((response) => response.json())
        .then((json) => {
            //setData(json)
            if (json.status == true) { 
              setTopBottomWashListData(json.wash)
              setLoading(false); }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
  }
  
}

const BeddingsOthersListData = () => {
  if(beddingsOthersListData == ""){
    setLoading(true);
    fetch(process.env.REACT_APP_MAIN_API + 'list/laundry/beddings_others')
        .then((response) => response.json())
        .then((json) => {
            //setData(json)
            if (json.status == true) { 
              setBeddingsOthersListData(json.wash)
              setLoading(false); }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
  }
  
}

const plusOne = (id, title, img_url, type, laundry_type, amount) => {
  try {
    //check if storage exists
    //Let's use contactList as our storage name

    let cart = localStorage.getItem("cart")
    if (cart !== null) {

        var newList = JSON.parse(cart) //JSON.parse converts Text to JavaScript object.

        //Check if record already exists
        let check = newList.filter(item => item.id === id)

        if (check.length > 0) {

            //update cart for selected item
            let data = check[0]
            let dataUpdate = JSON.parse(cart)
        
            let newTotalItems = data.total_items + 1
            let newAmount = parseFloat(amount) * newTotalItems

                    var objIndex = dataUpdate.findIndex((x => x.id === id));
                    dataUpdate[objIndex].total_items = newTotalItems
                    dataUpdate[objIndex].total_amount = newAmount
                    localStorage.setItem("cart", JSON.stringify(dataUpdate))

        }

        else {

        let newCart = {
          id: id,
          title: title,
          img_url: img_url,
          type: type,
          laundry_type: laundry_type,
          total_items: 1,
          amount: amount,          
          total_amount: amount    
        }
        
        newList.push(newCart) //Add new list to old list in local storage

        localStorage.setItem("cart", JSON.stringify(newList)) //JSON.stringify converts JS object to JSON


        }
    }
    else {

        //create a new storage

        let newCart = [{
          id: id,
          title: title,
          img_url: img_url,
          type: type,
          laundry_type: laundry_type,
          total_items: 1,
          amount: amount,          
          total_amount: amount
          
        }]

        localStorage.setItem("cart", JSON.stringify(newCart))
    }

} catch (e) {
    console.log(e) //for handling errors
}
setSelectedItmes(CartServices.TotalItems)
}

const lessOne = (id, title, img_url, type, laundry_type, amount) => {
  try {
    //check if storage exists
    //Let's use contactList as our storage name

    let cart = localStorage.getItem("cart")
    if (cart !== null) {

        var newList = JSON.parse(cart) //JSON.parse converts Text to JavaScript object.

        //Check if record already exists
        let check = newList.filter(item => item.id === id)

        if (check.length > 0) {

            //update cart for selected item
            let data = check[0]
            let dataUpdate = JSON.parse(cart)
        
            let newTotalItems = data.total_items - 1
            let newAmount = parseFloat(amount) * newTotalItems

            if(newTotalItems < 1){
              //delete
              var updated = dataUpdate.filter(x => x.id !== id)
               localStorage.setItem('cart', JSON.stringify(updated));

            }
            else{
              var objIndex = dataUpdate.findIndex((x => x.id === id));
              dataUpdate[objIndex].total_items = newTotalItems
              dataUpdate[objIndex].amount = newAmount
              localStorage.setItem("cart", JSON.stringify(dataUpdate))
            }

                    

        }
    }
    
} catch (e) {
    console.log(e) //for handling errors
}
setSelectedItmes(CartServices.TotalItems)
  }

    const TopItemsWash = () => {
      
        return (
            <>
                <div className="col-12 mb-5">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2">                

              {topWashListData.map(wash =>

<div key={wash._id} className="col">
                  <div className="card h-100">
                    <img src={FetchImage(wash.image)} className="card-img-top sh-22" alt={wash.title} />
                    <div className="card-body pb-2">
                      <div className="h6 mb-0 d-flex">
                        <span className="body-link d-block lh-1-25">
                          <span className="clamp-line sh-4" data-line="2">{wash.title}</span>
                        </span>
                      </div>
                    </div>
                    <div className="card-footer border-0 pt-0">                      
                      <div class="col-12 pe-0 d-flex align-items-center">
                      <div class="col-6 col-md-6 pe-0 d-flex align-items-center"> <div className="card-text mb-0">
                        <div><b>&#x20A6;{NumberFormat(wash.price)}</b></div>
                      </div>
                      {userDetails.account_type == "Admin"? 
                         <div className='m-3'> 
                         <i className='fa fa-pen text-primary' onClick={()=>editItem(wash._id)} style={{display: editingId == wash._id? `none` : `block`}}></i>
                        </div>
                        : ''}
                        </div>
                      <div class="col-6 col-md-6 pe-0 d-flex align-items-center">
                            <div class="input-group spinner sw-11" data-trigger="spinner">
                              <div class="input-group-text">
                                <button type="button" onClick={() => lessOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-down single px-2 d-flex justify-content-center" data-spin="down">-</button>
                              </div>
                              <div type="text" class="form-control text-center px-0" data-rule="quantity"> {getSeletedItems(wash._id)} </div>
                              <div class="input-group-text">
                                <button type="button" onClick={() => plusOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-up single px-2 d-flex justify-content-center" data-spin="up">+</button>
                              </div>
                            </div>
                          </div>                          
                          </div>
                    </div>
                  </div>
                </div>

)}
                
              </div>
            </div>
            </>
        )
    }


    const BottomItemsWash = () => {
      
      return (
          <>
              <div className="col-12 mb-5">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2">

              

            {bottomWashListData.map(wash =>

<div key={wash._id} className="col">
                <div className="card h-100">
                  <img src={FetchImage(wash.image)} className="card-img-top sh-22" alt={wash.title} />
                  <div className="card-body pb-2">
                    <div className="h6 mb-0 d-flex">
                      <span className="body-link d-block lh-1-25">
                        <span className="clamp-line sh-4" data-line="2">{wash.title}</span>
                      </span>
                    </div>
                  </div>
                  <div className="card-footer border-0 pt-0">                      
                    <div class="col-12 pe-0 d-flex align-items-center">
                    <div class="col-6 col-md-6 pe-0 d-flex align-items-center"> <div className="card-text mb-0">
                      <div><b>&#x20A6;{NumberFormat(wash.price)}</b></div>
                    </div>
                    {userDetails.account_type == "Admin"? 
                         <div className='m-3'> 
                         <i className='fa fa-pen text-primary' onClick={()=>editItem(wash._id)} style={{display: editingId == wash._id? `none` : `block`}}></i>
                        </div>
                        : ''}
                        </div>
                    <div class="col-6 col-md-6 pe-0 d-flex align-items-center">
                          <div class="input-group spinner sw-11" data-trigger="spinner">
                            <div class="input-group-text">
                              <button type="button" onClick={() => lessOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-down single px-2 d-flex justify-content-center" data-spin="down">-</button>
                            </div>
                            <div type="text" class="form-control text-center px-0" data-rule="quantity"> {getSeletedItems(wash._id)} </div>
                            <div class="input-group-text">
                              <button type="button" onClick={() => plusOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-up single px-2 d-flex justify-content-center" data-spin="up">+</button>
                            </div>
                          </div>
                        </div>                          
                        </div>
                  </div>
                </div>
              </div>

)}
              
            </div>
          </div>
          </>
      )
  }

  const TopBottomItemsWash = () => {
      
    return (
        <>
            <div className="col-12 mb-5">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2">

            

          {topBottomWashListData.map(wash =>

<div key={wash._id} className="col">
              <div className="card h-100">
                <img src={FetchImage(wash.image)} className="card-img-top sh-22" alt={wash.title} />
                <div className="card-body pb-2">
                  <div className="h6 mb-0 d-flex">
                    <span className="body-link d-block lh-1-25">
                      <span className="clamp-line sh-4" data-line="2">{wash.title}</span>
                    </span>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0">                      
                  <div class="col-12 pe-0 d-flex align-items-center">
                  <div class="col-6 col-md-6 pe-0 d-flex align-items-center"> <div className="card-text mb-0">
                    <div><b>&#x20A6;{NumberFormat(wash.price)}</b></div>
                  </div>
                  {userDetails.account_type == "Admin"? 
                         <div className='m-3'> 
                         <i className='fa fa-pen text-primary' onClick={()=>editItem(wash._id)} style={{display: editingId == wash._id? `none` : `block`}}></i>
                        </div>
                        : ''}
                        </div>
                  <div class="col-6 col-md-6 pe-0 d-flex align-items-center">
                        <div class="input-group spinner sw-11" data-trigger="spinner">
                          <div class="input-group-text">
                            <button type="button" onClick={() => lessOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-down single px-2 d-flex justify-content-center" data-spin="down">-</button>
                          </div>
                          <div type="text" class="form-control text-center px-0" data-rule="quantity"> {getSeletedItems(wash._id)} </div>
                          <div class="input-group-text">
                            <button type="button" onClick={() => plusOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-up single px-2 d-flex justify-content-center" data-spin="up">+</button>
                          </div>
                        </div>
                      </div>                          
                      </div>
                </div>
              </div>
            </div>

)}
            
          </div>
        </div>
        </>
    )
}

const BeddingsOthersItemsWash = () => {
      
  return (
      <>
          <div className="col-12 mb-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2">

          

        {beddingsOthersListData.map(wash =>

<div key={wash._id} className="col">
            <div className="card h-100">
              <img src={FetchImage(wash.image)} className="card-img-top sh-22" alt={wash.title} />
              <div className="card-body pb-2">
                <div className="h6 mb-0 d-flex">
                  <span className="body-link d-block lh-1-25">
                    <span className="clamp-line sh-4" data-line="2">{wash.title}</span>
                  </span>
                </div>
              </div>
              <div className="card-footer border-0 pt-0">                      
                <div class="col-12 pe-0 d-flex align-items-center">
                <div class="col-6 col-md-6 pe-0 d-flex align-items-center"> <div className="card-text mb-0">
                  <div><b>&#x20A6;{NumberFormat(wash.price)}</b></div>
                </div>
                {userDetails.account_type == "Admin"? 
                         <div className='m-3'> 
                         <i className='fa fa-pen text-primary' onClick={()=>editItem(wash._id)} style={{display: editingId == wash._id? `none` : `block`}}></i>
                        </div>
                        : ''}
                        </div>
                <div class="col-6 col-md-6 pe-0 d-flex align-items-center">
                      <div class="input-group spinner sw-11" data-trigger="spinner">
                        <div class="input-group-text">
                          <button type="button" onClick={() => lessOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-down single px-2 d-flex justify-content-center" data-spin="down">-</button>
                        </div>
                        <div type="text" class="form-control text-center px-0" data-rule="quantity"> {getSeletedItems(wash._id)} </div>
                        <div class="input-group-text">
                          <button type="button" onClick={() => plusOne(wash._id, wash.title, wash.image, 'Top', 'Wash', wash.price) } class="spin-up single px-2 d-flex justify-content-center" data-spin="up">+</button>
                        </div>
                      </div>
                    </div>                          
                    </div>
              </div>
            </div>
          </div>

)}
          
        </div>
      </div>
      </>
  )
}
return (
  <div className="row">
    <div className="col-lg-12 col-md-12 col-xl-12">

<Box sx={{bgcolor: 'background.paper' }}>
<TabContext value={value}>
  <Tabs
    value={value}
    onChange={handleChange}
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
  >
    <Tab label="Top" value={0} />
    <Tab label="Bottom" value={1} onClick={BottomListData} />
    <Tab label="Top & Bottom" value={2} onClick={TopBottomListData} />
    <Tab label="Beddings & Others" value={3} onClick={BeddingsOthersListData} />
  </Tabs>

  <TabPanel value={0}>{isLoading ? Constants.loader : <TopItemsWash/> }</TabPanel>
  <TabPanel value={1}>{isLoading ? Constants.loader : <BottomItemsWash/> }</TabPanel>
  <TabPanel value={2}>{isLoading ? Constants.loader : <TopBottomItemsWash/> }</TabPanel>
  <TabPanel value={3}>{isLoading ? Constants.loader : <BeddingsOthersItemsWash/> }</TabPanel>
  </TabContext>
</Box>

</div>

{selectedItmes < 1 ? "" : 
<div style={{position:'-webkit-sticky', position: 'sticky', position: 'fixed', zIndex:500, bottom:25}}>
                  <a href="/cart" className="btn btn-icon btn-icon-end btn-primary w-50" type="button">
                    <span>({selectedItmes}) Checkout <i className='fas fa-arrow-right'></i></span>
                  </a>
                </div>
                }
</div>
)

}

export default WashItems;