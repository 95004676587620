import React, { useEffect, useState } from 'react';
import SearchOrder from '../general_components/SearchOrder';
import WashIronItems from '../laundry_service_components/WashIronItems';
import CartServices from '../services/CartServices';
import PageBarTitle from '../utilities/PageBarTitle';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';

const WashAndIron = () => {

    const pageName = "Wash and Iron";

    const [selectedItmes, setSelectedItmes] = useState(0);

    const staticDiv = {position:'-webkit-sticky', position: 'sticky', position: 'fixed', zIndex:100, backgroundColor: '#ffffff'}
  
    useEffect(() =>{
        PageBarTitle(pageName)
      setSelectedItmes(CartServices.TotalItems)
    });

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
        <div className='mb-3'> <h2>{pageName}</h2> </div> 
          <SearchOrder/>
          <WashIronItems/>
        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default WashAndIron;