import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const Profile = () => {

    const [pageName, setPageName] = React.useState("Profile Account");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [loggedUserData, setLoggedUserData] = useState([])
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [stateOfResidence, setStateOfResidence] = useState(null);
    const [cityOfResidence, setCityOfResidence] = useState(null);
    const [pickUpAddress, setPickUpAddress] = useState(null);
    const [landMark, setLandMark] = useState(null);
    const [nearestBusStop, setNearestBusStop] = useState(null);
    const [note, setNote] = useState(null);
    const [isLogged, setIsLoggedIn] = useState(false)
    const [userId, setUserId] = useState(null)
    const [enterUserData, setEnterUserData] = useState(false);
  
    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    useEffect(() => {
        PageBarTitle(pageName);
        IsLoggedIn()
        if(userId === null){
            GetUserDetails()
          }
        GetUserAddress()
    });

    const EditProfileButton = () =>{
        setEnterUserData(true)
    }

    const PostUserdata = () => {
        setLoading(true)
setLoading(true)
let user = {
    user_id: userId, 
    firstname: firstName, 
    lastname: lastName, 
  email: email, 
  phone: phone, 
}

const postOptions = {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +token
  },
  body: JSON.stringify(user)
};

fetch(process.env.REACT_APP_MAIN_API + 'update_profile', postOptions)
  .then((response) => response.json())
  .then((json) => {
      if (json.status === true) {
          //Get User ID
          setErrMsg(null)
          window.location.replace("/profile")//setEnterUserData(false)
         // setLoading(false)
      }
      else {
          setErrMsg(json.message)
          setLoading(false)
      }

  })
  .catch((error) => console.error(error))
  .finally(() => setLoading(false));
        }
      


    const GetUserDetails = () =>{
        fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
          .then((response) => response.json())
          .then((json) => {
              if (json.status == true) { 
                setLoggedUserData(json.message[0]);                
                GetVendorDetails()

                if(loggedUserData != "")
                setUserId(loggedUserData.user_id)
                setFirstName(loggedUserData.firstname)
                setLastName(loggedUserData.lastname)
                setPhone(loggedUserData.phone)
                setEmail(loggedUserData.email)   
                       
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))
      }

      const GetUserAddress = () =>{
        fetch(process.env.REACT_APP_MAIN_API +'get_user_address_by_token', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
                let x = json.message[0];
                setStateOfResidence(x.state_of_residence)
                setCityOfResidence(x.city_of_residence)
                setLandMark(x.landmark)
                setNearestBusStop(x.nearest_busstop)
                setPickUpAddress(x.pickup_address)
                setNote(x.note)
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
      }

      const [vendorDetails, setVendorDetails] = useState([])
      const GetVendorDetails = () =>{
        fetch(process.env.REACT_APP_MAIN_API + 'get_vendor_details_by_id', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status) { 
              setVendorDetails(json.message)
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
      }

      const IsLoggedIn = async () =>{
        fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
        .then((response) => response.json())
            .then((json) => {
                 
                if (json.status == true) { 
                    //return true;
                }
                else{
                  window.location.replace("/login")
                }
            })
            .catch((error) => console.error(error))
            .finally(console.log("Done"))           
        }

      const copyToClipboard = (text) => {
        const el = text
        navigator.clipboard.writeText(text)
        alert("link copied")
      }

      const profileCard = 
      <div class="card-body">
                  <div class="d-flex align-items-center flex-column mb-4">
                    <div class="d-flex align-items-center flex-column">
                      <div class="sw-13 position-relative mb-3">
                        <img src={vendorDetails != ""? vendorDetails.logo : "/img/avatar.jpg"} 
                        class="img-fluid rounded-xl" alt="thumb" />
                      </div>
                      <div class="h5 mb-0">{loggedUserData.firstname} {loggedUserData.lastname}</div>
                      <div class="text-muted">Account Type: {loggedUserData.account_type}</div>
                      <div class="text-muted">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="cs-icon cs-icon-pin me-1"><path d="M3.5 8.5C3.5 -0.191416 16.5 -0.191352 16.5 8.5C16.5 12.6585 12.8256 15.9341 11.0021 17.3037C10.4026 17.754 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.5Z"></path></svg>
                        <span class="align-middle">{stateOfResidence}, {cityOfResidence}</span>
                      </div>
                    </div>
                  </div>
                  <div class="nav flex-column" role="tablist">
                   <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#projectsTab" role="tab">
                      <i className='fas fa-mobile me-3'></i>
                      <span class="align-middle">{loggedUserData.phone}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
                    <i className='fas fa-envelope me-3'></i>
                    <span class="align-middle">{loggedUserData.email}</span>
                    </a>
                    <a onClick={ () => EditProfileButton() } class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#friendsTab" role="tab">
                    <i className='fas fa-edit me-3'></i>
                      <span class="align-middle">Edit</span>
                    </a>                    
                  </div>
                </div>

const VendorProfileCard = 
<div class="card-body">
            <div class="d-flex align-items-center flex-column mb-4">
              <div class="d-flex align-items-center flex-column">
               <div class="h5 mb-0">{vendorDetails.store_name}</div>
               <div class="text-muted">Visibility: {vendorDetails.status === 0? <span className="text-danger">Pending</span> : <span className="text-success">Visible</span>}</div>
               <div class="text-muted">Status: {vendorDetails.is_verified === 0? <span className="text-danger">Not Verified</span> : <span className="text-success"><b>Verified</b></span>}</div>
                <div class="text-muted">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="cs-icon cs-icon-pin me-1"><path d="M3.5 8.5C3.5 -0.191416 16.5 -0.191352 16.5 8.5C16.5 12.6585 12.8256 15.9341 11.0021 17.3037C10.4026 17.754 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.5Z"></path></svg>
                  <span class="align-middle">{vendorDetails.state}, {vendorDetails.city}</span>
                </div>
              </div>
            </div>
            <div class="nav flex-column" role="tablist">
            <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#projectsTab" role="tab">
                <i className='fas fa-mobile me-3'></i>
                <span class="align-middle">{vendorDetails.phone}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-envelope me-3'></i>
              <span class="align-middle">{vendorDetails.email}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-compass me-3'></i>
              <span class="align-middle">{vendorDetails.store_address}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-landmark me-3'></i>
              <span class="align-middle">{vendorDetails.landmark}</span>
              </a>
              <a onClick={() => copyToClipboard(Constants.Website()+vendorDetails.username+"/vendor")} data-bs-toggle="tab" 
              class="nav-link px-0 border-bottom border-separator-light" 
              href={Constants.Website()+vendorDetails.username+"/vendor"} role="tab">
                <i className='fas fa-link me-3'></i>
                <span class="align-middle">{Constants.Website()+vendorDetails.username}/vendor</span>
              </a>
              </div>
          </div>

                const profileCardForm = 
                <div class="card-body">
                <div className="row g-3">
  <div className="col-md-6 mb-3">
    <label className="form-label">First Name</label>
    <input className="form-control" 
    value={firstName}
    onChange={e => setFirstName(e.target.value)}
    />
  </div>
  <div className="col-md-6 mb-3">
    <label className="form-label">Last Name</label>
    <input className="form-control" 
    value={lastName} 
    onChange={e => setLastName(e.target.value)}
    />
  </div>
</div>
<div className="row g-3">
<div className="col-md-6 mb-3">
    <label className="form-label">Phone</label>
    <input className="form-control" 
    value={phone} 
   onChange={e => setPhone(e.target.value)}
    />
  </div>
  <div className="col-md-6 mb-3">
    <label className="form-label">Email</label>
    <input className="form-control" value={email}
    onChange={e => setEmail(e.target.value)}/>
  </div>
</div>
{errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
{loading === true? Constants.loader : 
  <button onClick={() => PostUserdata()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
  <span>Update</span> 
</button>
}
                </div>
    
const ContentBox = 
<div className="row">
<div class="col-12 col-xl-12 col-xxl-12">
              <h2 class="small-title">Profile</h2>
              <div class="card mb-5">
               
                {enterUserData === false? profileCard : profileCardForm}

                
              </div>
            </div>

            <div class="col-12 col-xl-12 col-xxl-12">
              <h2 style={{display:vendorDetails != "" ? `block` : `none`}} class="small-title">Company Details</h2>
              <div class="card mb-5">
               
                {enterUserData === false && vendorDetails != "" ? VendorProfileCard : ''}

                
              </div>
            </div>
            
</div>

const MainContent = 

<div className="col-12 col-lg">
        
    {ContentBox}

    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
                <div>

                {MainContent} 

              </div>
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default Profile;