import React, { Component, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import CartServices from '../services/CartServices';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import Greetings from '../utilities/Greetings';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const Login = () => {

    const [pageName, setPageName] = React.useState("Login");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [emailPhone, setEmailPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    useEffect(() => {
        LogOut()
        PageBarTitle(pageName)
        LoginCheck()
    },[]);

    
    
    const LogOut = () =>{
        if(token != "" && token !== null ){

            var options = {  
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': token
                }
              }

        fetch(process.env.REACT_APP_MAIN_API + 'logout', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) {
              localStorage.removeItem("cart")
              localStorage.removeItem("loginToken")
            }
        })
        .catch((error) => console.error(error))
        .finally(() => console.log("Done"));    
              }
      }
    

    const LoginCheck = async () =>{

      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                if(CartServices.TotalItems() > 0){
                  window.location.href="/cart";
              }else{
                window.location.href="/dashboard";
              }
              }
              else{
               // window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))  

      }

      const [vendorDetails, setVendorDetails] = useState([])
      const GetVendorDetails = () =>{
        var options_ = {  
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +token
          }
        }
        fetch(process.env.REACT_APP_MAIN_API + 'get_vendor_details_by_id', options_)
        .then((response) => response.json())
        .then((json) => {
            if (json.status) { 
              setVendorDetails(json.message)
            }else{
              //alert(json.message)
            window.location.href="/signup_vendor";
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
      }
    
      const onLoginEnter = (event) =>{
        if(event.keyCode === 13){
          UserLogin()
        }
              }

    const UserLogin = () => {
        setErrMsg(null)
        setLoading(true)
        let user = {
            email: emailPhone, 
            password: password
        }

        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        };
        
fetch(process.env.REACT_APP_MAIN_API + 'login', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === true) {
                    //localStorage.setItem('loginToken', json.loginToken);
                    
                    setCookie("token", json.loginToken, {
                        path: "/"
                      });
                      setToken(json.loginToken)
                      
                      
                        setPassword("")
                        setErrMsg(null)
                        if(json.accountType === "Vendor") 
                        {
                          GetVendorDetails()
                        } 
                        if(CartServices.TotalItems() > 0){
                          window.location.href="/cart";
                      }
                    else{
                            window.location.href="/";
                        }                        
                }
                else {
                    setPassword(null)
                    setErrMsg(json.message)
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    
    return (        
        <>
        <main>
        <div className='container'>
            <div className='row d-flex align-content-center'>
        <div className="px-5">
                <div className="">
                  <a href="/">
                    {Constants.logo}
                  </a>
                </div>
                <div className="row mb-5">
                  <h2 className="cta-1 mb-0 text-primary">{Greetings()}</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Please use your credentials to login below or create new account.</p>
                  <p className="h6">
                    <Link to="/signup" className="text-large">Create New Account!</Link>
                    </p>
                </div>
                <div>
                <div className='row col-12'>
                  <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="addon-wrapping"><i className='fas fa-user'></i></span>
                        <input type="text" class="form-control" placeholder="Email or Phone" value={emailPhone} onChange={e => setEmailPhone(e.target.value) } />
                    </div>
                    <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="addon-wrapping"><i className='fas fa-lock'></i></span>
                        <input class="form-control" name="password" type="password" value={password} onChange={e => setPassword(e.target.value) } onKeyDown={e => onLoginEnter(e)} placeholder="Password" />
                        <span class="input-group-text"><Link to="/password_reset">Forgot?</Link></span>
                    </div>  
                    {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
                    {loading == true? Constants.loader :                   
                        <button onClick={() => UserLogin()} className="btn btn-lg btn-primary btn-block">Login</button>
                    }
                        </div>
                </div>
              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default Login;