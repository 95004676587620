import React, { Component, useEffect, useState, forwardRef, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Constants from '../utilities/Constants';
import FetchImage from '../utilities/FetchImage';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';
import Footer from './Footer';
import Header from './Header';
import PageTile from './PageTitle';
import PopularLaundry from './PopularLaundry';
import SearchOrder from './SearchOrder';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import UserServices from '../services/UserServices';
import { useCookies } from 'react-cookie';




const OrderDetails = () => {

    const { order_id } = useParams();

    const [pageName, setPageName] = React.useState("Order Details");
    const [orderDetails, setOrderDetails] = React.useState([]);
    const [orderStatus, setOrderStatus] = React.useState(0);
    const [deliveryAddress, setDeliveryAddress] = React.useState(null);
    const [totalItems, setTotalItems] = React.useState(0);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [grandTotalAmount, setGrandTotalAmount] = React.useState(0);
    const [shippingAmount, setShippingAmount] = React.useState(0);
    const [note, setNote] = React.useState(null);
    const [orderId, setOrderId] = React.useState(order_id);
    const [dateAdded, setDateAdded] = React.useState(null);
    const [status, setStatus] = React.useState(0);
    const [userDetails, setUserDetails] = React.useState([]);
    const [staffDetails, setStaffDetails] = React.useState([]);
    const [loggedStaffDetails, setLoggedStaffDetails] = React.useState([]);

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    const GetUserDetails = (id) =>{
      fetch(process.env.REACT_APP_MAIN_API + id+'/get_user_details_by_id', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
              setUserDetails(json.message[0]);        
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
    }

    const GetStaffDetails = (id) =>{
      fetch(process.env.REACT_APP_MAIN_API +id+'/get_staff_details_by_id', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
              setStaffDetails(json.message[0]);        
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
    }

    const LoggedStaffDetails_ = () =>{
      fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) { 
              setLoggedStaffDetails(json.message[0]);        
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))
    }

    useEffect(() => {
      LoggedStaffDetails_();
        FetchOrderDetails(orderId)
        FetchDeliveryAddress(orderId)
        PageBarTitle(pageName)
    },[]);

    const FetchOrderDetails = (id) =>{
        if(orderDetails == ""){

            fetch(process.env.REACT_APP_MAIN_API + id+'/order_details')
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) 
            { 
                GetUserDetails(json.message.user_id)
                
                if(json.message.staff_id && json.message.staff_id != '') 
                {GetStaffDetails(json.message.staff_id)}
                
                setOrderDetails(json.message.order_details); 
                setOrderStatus(json.message.status); 
                setDateAdded(json.message.date_added)
                setTotalAmount(json.message.total_amount)
                setTotalItems(json.message.total_items)
                let amt = json.message.total_amount;
                if(amt < 10000){
                    let grandtotal = amt + 500
                    setGrandTotalAmount(grandtotal)
                    setShippingAmount(500)
                }
                else{
                    setGrandTotalAmount(amt)
                }
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))

        }        
    }

    const FetchDeliveryAddress = (id) =>{
        if(deliveryAddress == null){

            fetch(process.env.REACT_APP_MAIN_API + id+'/address')
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) 
            { 
                setDeliveryAddress(json.message.pickup_address+", "
                +json.message.city_of_residence+", "
                +json.message.state_of_residence); 
                setNote(json.message.note)
            }
        })
        .catch((error) => console.error(error))
        .finally(console.log("Done"))

        }
        
    }

    const UpdateStatus = () => {
     
        setLoading(true)
        let data = {
          staff_id: loggedStaffDetails.user_id,
          order_id: orderId,
          status: status
        }

        const postOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +token
            },
            body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_MAIN_API + 'staff/update_order', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === true) {
                  FetchOrderDetails(orderId)
                  setErrorMsg(null)                  
                  alert(json.message)
                  window.location.reload();
                    setLoading(false)
                }
                else {
                  setErrorMsg(json.message)
                  alert(json.message)
                    setLoading(false)
                }

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
          
    }

    let ref = useRef();
    const ComponentToPrint = forwardRef((props, ref) => {
        return <div className='row' ref={ref}>
<div className="row d-flex flex-row align-items-center">
              <h1><span className='text-small mr-3'>Order ID#: </span> {orderId} </h1>
                <div className="col-12 col-md-6">
                  <img src="/img/avatar.jpg" alt="logo" style={{width: 50, height: 50}} />
                </div>
                <div className="col-12 col-md-6 text-end">
                  <div className="mb-2">{Constants.AppTitle()}</div>
                  <div className="text-small text-muted">{Constants.Address()}</div>
                  <a href={'tel:'+Constants.Phone()} className="text-small text-muted">{Constants.Phone()}</a>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col">
                  <div className="separator separator-light"></div>
                </div>
              </div>
              <div className="row g-1 mb-5">
                <div className="col-12 col-md-8">
                  <div className="py-3">
                    <div>Delivery Address</div>
                    <div>{deliveryAddress}</div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="py-3 text-md-end">
                    <div>Order ID: {orderId}</div>
                    <div className='text-small'>{dateAdded}</div>
                  </div>
                </div>
              </div>

              <div>
                <div className="row mb-4 d-none d-sm-flex">
                <div className="col-3">
                    <p className="mb-0 text-small text-muted">ITEM NAME</p>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 text-small text-muted">COUNT</p>
                  </div>
                  <div className="col-3">
                    <p className="mb-0 text-small text-muted">LAUNDRY TYPE</p>
                  </div>
                  <div className="col-3 text-end">
                    <p className="mb-0 text-small text-muted">PRICE</p>
                  </div>
                </div>

                {orderDetails.map(orders =>
                <div className="row mb-4 mb-sm-2">
                  <div className="col-12 col-sm-3">
                    <h6 className="mb-0">{orders.title}</h6>
                  </div>
                  <div className="col-12 col-sm-3">
                    <p className="mb-0 text-alternate">{orders.total_items}</p>
                  </div>
                  <div className="col-12 col-sm-3">
                    <p className="mb-0 text-alternate">{orders.laundry_type}</p>
                  </div>
                  <div className="col-12 col-sm-3 text-sm-end">
                    <p className="mb-0 text-alternate">&#x20A6; {NumberFormat(orders.total_amount)}</p>
                  </div>
                </div>
                )
                }
              </div>

              <div className="row mt-5 mb-5">
                <div className="col">
                  <div className="separator separator-light"></div>
                </div>
              </div>

              <div className="row">
                <div className="col text-sm-end text-muted">
                  <div>Items :</div>
                  <div>Amount :</div>
                  <div>Shipping :</div>
                  <div>Total :</div>
                </div>
                <div className="col-auto text-end">
                  <div>{totalItems}</div>
                  <div>&#x20A6; {NumberFormat(totalAmount)}</div>
                  <div>&#x20A6; {NumberFormat(shippingAmount)}</div>
                  <div>&#x20A6; {NumberFormat(grandTotalAmount)}</div>
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col">
                  <div className="separator separator-light"></div>
                </div>
              </div>

              <div className='text-dark'>Note</div>
              <div className='col'>{note}</div>
              <div className="separator separator-light"></div>
{/* Customer details */}
              <div className="row mt-5 mb-5">
                <div className="col">
                <p className="text-dark">Customer Name and Phone</p>
                  <div className="separator separator-light"></div>
                  <table className='table table-striped'>
                    <tbody>
                    <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    </tr>
                    <tr>
                    <td>{userDetails.firstname} {userDetails.lastname}</td>
                    <td>{userDetails.phone}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* staff details */}
              <div className="row mt-5 mb-5">
                <div className="col">
                <p className="text-dark">Staff Incharge</p>
                  <div className="separator separator-light"></div>
                  <table className='table table-striped'>
                    <tbody>
                    <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    </tr>
                    <tr>
                    <td>{staffDetails.firstname} {staffDetails.lastname}</td>
                    <td>{staffDetails.phone}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
        </div>
    })

    const ProgressBar = 
    <>
             <div className='row'>
             <div className="col-6 col-lg mb-5">
             <h2 className="small-title">Order Status: <span className='text-dark'>{Constants.GetOrderStatus(orderStatus)}</span></h2>
                   </div>
                   {loggedStaffDetails.account_type == "Staff"? 
    <div className="col-6 col-lg  mb-5">
    <div class="input-group mb-6">
        <select className="form-control" onChange={e => setStatus(e.target.value)}>
          <option value={orderStatus}>Current: {Constants.GetOrderStatus(orderStatus)}</option>
          <option value={'0'}>Pending</option>
          <option value={'1'}>Picked Up</option>
          <option value={'2'}>Laundry started</option>
          <option value={'3'}>Laundry Done</option>
          <option value={'4'}>Completed</option>
          <option value={'5'}>Rejected</option>
          </select>
        {loading === true? Constants.loader : 
        <div onClick={()=>UpdateStatus()} className="input-group-text text-white bg-primary">Change Order Status</div>
    }
    </div>
    </div>
    : ""}
             </div>
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="progress sh-2">
                        <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{width: Constants.GetOrderProgress(orderStatus)+'%'}}>{Constants.GetOrderProgress(orderStatus)}%</div>
                      </div>
                    </div>
                  </div>
                </>

    const GetOrderDetails = 
    <div className="card-body">
        
        <SearchOrder />
        
       
        <ReactToPrint content={() => ref.current}>
   <PrintContextConsumer>
          {({ handlePrint }) => (
            <button className='btn btn-primary text-right m-5' onClick={handlePrint}>
                Print <i className='fa fa-print'></i>
            </button>
          )}
        </PrintContextConsumer>
        </ReactToPrint>

        {ProgressBar}
        
               </div>

const NoItemFound = 
<div>
<SearchOrder />
<div className="col-12 col-lg order-1 order-lg-0">
    Order not found! </div>
    </div>

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>

        {PageTile(pageName)}

        {orderDetails !="" ? <>
        {GetOrderDetails}
        <ComponentToPrint ref={ref} />
        </>
        : NoItemFound  }

        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default OrderDetails;