import React, { Component, useEffect, useState } from 'react';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import SearchOrder from '../general_components/SearchOrder';
import PageBarTitle from '../utilities/PageBarTitle';

const PageNotFound = () => {

    const [pageName, setPageName] = React.useState("Page Not Found");

    useEffect(() => {
        PageBarTitle(pageName)
    });

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
        <SearchOrder/>
        <div>
            <h1>OPSS!!!</h1>
            <h4>Page Not Found</h4>
        </div>
        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default PageNotFound;