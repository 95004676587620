
const AllCarts = () => {
        /*
    try {
        let cart = localStorage.getItem("cart")
        let x = JSON.parse(cart)
            return x;
    } catch (error) {
        console.log("Error Message: "+ error)
        return [];
    }
    */

            
            try {
        let cart = localStorage.getItem("cart")
        if(cart !== null){
           let x = JSON.parse(cart)
            return x;
        } else{
            return []; 
        }
    } catch (error) {
        console.log("Error Message: "+ error)
        return [];
    }
            
}

const TotalItems = () => {
    try {
        let cart = localStorage.getItem("cart")
        if(cart !== null){
           let x = JSON.parse(cart)
                    
            let total_items = 0;
            for (let y of x) {
                if (parseInt(y.total_items) > 0) {
                    total_items += parseInt(y.total_items)
    
                }
            }
            return total_items;
        } else{
            return 0; 
        }
    } catch (error) {
        console.log("Error Message: "+ error)
        return 0;
    }
}

const TotalAmount = () => {
    try {
        let cart = localStorage.getItem("cart")
        if(cart !== null){
           let x = JSON.parse(cart)
            let total_amount = 0;
            for (let y of x) {
                if (parseFloat(y.total_amount) > 0) {
                    total_amount += parseFloat(y.total_amount)
    
                }
            }
            return total_amount;
        } else{
            return 0; 
        }
    } catch (error) {
        console.log(error)
        return 0;
    }
}

const Shipping = () => {
    if(TotalAmount() === 0){
        return 0;
            } 
            else if(TotalAmount() < 10000){
                return 500;
                    } 
                            else{
        return 0;
    }    
}

const GrandTotal = () => {
   let gt = TotalAmount() + Shipping();
    return gt;
}

const ReduceSelectedCartItem = (id, amount) => {

    try {
        //check if storage exists
        let cart = localStorage.getItem("cart")
        if (cart !== null) {
    
            var newList = JSON.parse(cart) //JSON.parse converts Text to JavaScript object.
    
            //Check if record already exists
            let check = newList.filter(item => item.id === id)
    
            if (check.length > 0) {
    
                //update cart for selected item
                let data = check[0]
                let dataUpdate = JSON.parse(cart)
            
                let newTotalItems = data.total_items - 1
                let newAmount = data.total_amount - parseFloat(amount)
                
                if(newTotalItems < 1){
                  //delete
                  var updated = dataUpdate.filter(x => x.id !== id)
                   localStorage.setItem('cart', JSON.stringify(updated));
    
                }
                else{
                  var objIndex = dataUpdate.findIndex((x => x.id === id));
                  dataUpdate[objIndex].total_items = newTotalItems
                  dataUpdate[objIndex].total_amount = newAmount
                  localStorage.setItem("cart", JSON.stringify(dataUpdate))
                }
    
            }
        }
        
    } catch (e) {
        console.log(e) //for handling errors
    }
}

const IncreaseSelectedCartItem = (id, amount) => {

    try {
        //check if storage exists
        let cart = localStorage.getItem("cart")
        if (cart !== null) {
    
            var newList = JSON.parse(cart) //JSON.parse converts Text to JavaScript object.
    
            //Check if record already exists
            let check = newList.filter(item => item.id === id)
    
            if (check.length > 0) {
    
                //update cart for selected item
                let data = check[0]
                let dataUpdate = JSON.parse(cart)
            
                let newTotalItems = data.total_items + 1
                let newAmount = parseFloat(data.total_amount) + parseFloat(amount)
                
                if(newTotalItems < 1){
                  //delete
                  var updated = dataUpdate.filter(x => x.id !== id)
                   localStorage.setItem('cart', JSON.stringify(updated));
    
                }
                else{
                  var objIndex = dataUpdate.findIndex((x => x.id === id));
                  dataUpdate[objIndex].total_items = newTotalItems
                  dataUpdate[objIndex].total_amount = newAmount
                  localStorage.setItem("cart", JSON.stringify(dataUpdate))
                }
    
            }
        }
        
    } catch (e) {
        console.log(e) //for handling errors
    }
}

const DeleteSelectedCartItem = (id) => {
    try {
        //check if storage exists
        //Let's use contactList as our storage name
    
        let cart = localStorage.getItem("cart")
        if (cart !== null) {
    
            var newList = JSON.parse(cart) //JSON.parse converts Text to JavaScript object.
    
            //Check if record already exists
            let check = newList.filter(item => item.id === id)
    
            if (check.length > 0) {
    
                //update cart for selected item
                let data = check[0]
                let dataUpdate = JSON.parse(cart)
            
                  //delete
                  var updated = dataUpdate.filter(x => x.id !== id)
                   localStorage.setItem('cart', JSON.stringify(updated));
    
            }
        }
        
    } catch (e) {
        console.log(e) //for handling errors
    }
}

const ClearOrderId = () => {
    try {

        localStorage.removeItem("randString")
    
    }catch(e){
      console.log(e) 
    }    
  }

  const clearAllCart = () => {
    try {

        localStorage.removeItem("cart")
    
    }catch(e){
      console.log(e) 
    }    
  }

export default {AllCarts, 
    TotalItems, 
    TotalAmount, 
    Shipping, 
    GrandTotal, 
    ReduceSelectedCartItem, 
    DeleteSelectedCartItem,
IncreaseSelectedCartItem,
ClearOrderId,
clearAllCart
}
    