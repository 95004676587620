import React, { Component, useEffect, useState } from 'react';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';


const SearchOrder = () => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null)
    const [search, setSearch] = React.useState(null)

    const SearchOrderDetailsById = (id) =>{
        setErrorMsg(null)
        setLoading(true)
        if(id === null){
            setLoading(false)
    setErrorMsg("Enter a order ID")
        }else{
            fetch(process.env.REACT_APP_MAIN_API + id+'/order_details')
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) 
                { 
                    //setLoading(false)
                    window.location.replace("/"+id+"/order_details")
                }
                else{
                    setLoading(false)
                    setErrorMsg("No result found for "+id+". Please check the ID and try again.")
                }
            })
            .catch((error) => console.error(error))
            .finally(setLoading(false))
        }
        

    }

     
    return ( 
               <>
               {errorMsg !== null ? ShowMessage.ErrorMessage(errorMsg): "" }
        <div class="input-group mb-6">
        <input type="search" className="form-control" onKeyPress={event =>{
            if(event.key === 'Enter'){
                SearchOrderDetailsById(search)}
            }
        }  value={search} onChange={e => setSearch(e.target.value)} placeholder="Enter Order ID" />
        {loading === true? Constants.loader : 
        <div onClick={() => SearchOrderDetailsById(search)} class="input-group-text">Track Order </div>
    }
    </div>
    </>
    )
}

export default SearchOrder;