import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import NigeriaStatesLgas from '../utilities/NigeriaStatesLgas';
import PageBarTitle from '../utilities/PageBarTitle';
import Randoms from '../utilities/Randoms';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';
import { useCookies } from 'react-cookie';
import FilesHandler from '../utilities/FilesHandler';

const SignupVendor = () => {

    const [pageName, setPageName] = React.useState("Vendor Registration");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [establishments, setEstablishments] = useState(1);
    const [userName, setUserName] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [stateOfResidence, setStateOfResidence] = useState(null);
    const [states, setStates] = useState([]);
    const [lgas, setLgas] = useState([])
    const [cityOfResidence, setCityOfResidence] = useState(null);
    const [officeAddress, setOfficeAddress] = useState(null);
    const [landMark, setLandMark] = useState(null);
    const [adsSource, setAdsSource] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logo_, setLogo_] = useState(null);
    const [logoFile, setLogoFile] = useState();
    const [govIDCard, setGovIDCard] = useState(null);
    const [govIDCard_, setGovIDCard_] = useState(null);
    const [businessCertificate, setBusinessCertificate] = useState(null);
    const [businessCertificate_, setBusinessCertificate_] = useState(null);
    const [dateAdded, setDateAdded] = useState(new Date().toLocaleString());
    const [orderId, setOrderId] = useState(Randoms.RandomStrings(10));
    const [userId, setUserId] = useState(null)

    const [enterUserData, setEnterUserData] = useState(true);
    const [enterAddress, setEnterAddress] = useState(false);

    const [loggedUserData, setLoggedUserData] = useState([])

    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    const GetUserDetails = () =>{
        fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
          .then((response) => response.json())
          .then((json) => {
              if (json.status == true) { 
                  var result = json.message[0];
                setLoggedUserData(result);
                setUserId(result.user_id)
                setFirstName(result.firstname)
                setLastName(result.lastname)
                setPhone(result.phone)
                setEmail(result.email)
                setAccountType(result.account_type)
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))
      }
    
    useEffect(() => {
        GetUserDetails();
        PageBarTitle(pageName)
        setStates(NigeriaStatesLgas.States)
        IsLoggedIn();
        
    }, []);

    const IsLoggedIn = () =>{
        let x = UserServices.CheckIfLoggedIn
        if(x !== null){
          if(!x){
              window.location.replace("/login")
          }
        }        
      }


    const GetLGAs = (stateName) => {
        setLgas(NigeriaStatesLgas.GetLgas(stateName))
      }
  
      const checkLogo = async (x) =>{
        setErrMsg(null)
        setLoading(true)
        //check files
        if(FilesHandler.allowedImages(x.name) == false){
          setErrMsg("Image type not allowed")                      
          setLoading(false)
        }
        //check image size
        else if(FilesHandler.validateSelectedFile(x).status == 0)
        {          
          setErrMsg(FilesHandler.validateSelectedFile(x).message)                      
           setLoading(false)
        }
        else{
          let base64 = await FilesHandler.convertBase64(x)
          
          //const newImg = FilesHandler.resizeImage(base64, 400, 350)
          //console.log(newImg)
          //alert(newImg) 
          //base64 = await FilesHandler.convertBase64(newImg)
          //const base64Canvas = newImg.split(';base64,')[1];
          
          setLogo(base64)   
          setLoading(false)       
      }
      
      }

      const CheckOutOrder = async () =>{
          checkLogo(logoFile)
          if(logo.length > 20) PostUserdata() 
          else setLoading(false)
        // PostAddress()
      }

      const NextButton = () =>{
        setErrMsg(null)
        setEnterUserData(false)
        setEnterAddress(true)
        setLoading(false)
      }
  
      const PrevButton = () =>{
        setErrMsg(null)
        setEnterUserData(true)
        setEnterAddress(false)
        setLoading(false)
      }
  
      const PostUserdata = () => {
          setLoading(true)
          let user = {
            user_id: userId,
            state: stateOfResidence,
            city: cityOfResidence,
            email: email,
            phone: phone,
            firstname: firstName,
            lastname: lastName,
            username: userName,
            establishment_nums: establishments,
            store_address: officeAddress,
            landmark: landMark,
            store_name: companyName,
            ads_source: adsSource,
            business_certificate: businessCertificate,
            id_card: govIDCard,
            logo: logo
          }
  
          const postOptions = {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token
            },
            body: JSON.stringify(user)
          };
  
          fetch(process.env.REACT_APP_MAIN_API + 'vendor_application', postOptions)
              .then((response) => response.json())
              .then((json) => {
                  if (json.status === true) {
  
                      setErrMsg(null)
                      //setLoading(false)
                      window.location.href = "/profile";

                  }
                  else {
                    setErrMsg(json.message)                      
                      setLoading(false)
                  }
  
              })
              .catch((error) => console.error(error))
              .finally(() => setLoading(false));
            
      }

      {/*Base64 image upload*/}

      const handleFileReadLogo = async (event) => {
        const file = event.target.files[0]
        setLogoFile(file)
        checkLogo(file)
      }

      const handleFileReadCert = async (event) => {
        const file = event.target.files[0]
        const base64 = await FilesHandler.convertBase64(file)
       // console.log(base64)
        setBusinessCertificate(base64)
      }

      const handleFileReadIDCard = async (event) => {
        const file = event.target.files[0]
        const base64 = await FilesHandler.convertBase64(file)
       // console.log(base64)
        setGovIDCard(base64)
      }


      
      const UserData =  
      <>
      <div className="row g-3">
        <div className="col-md-6 mb-3">
          <label className="form-label">First Name</label>
          <input className="form-control" 
          value={firstName} 
          onChange={e => setFirstName(e.target.value)} 
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Last Name</label>
          <input className="form-control" 
          value={lastName} 
          onChange={e => setLastName(e.target.value)} 
          />
        </div>
      </div>
      <div className="row g-3">
      <div className="col-md-6 mb-3">
          <label className="form-label">Phone</label>
          <input className="form-control" 
          value={phone} 
          onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Email</label>
          <input className="form-control" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
      </div>
      <div className="row g-3">
      <div className="col-md-6 mb-3">
          <label className="form-label">Company Name</label>
          <input className="form-control"
          value={companyName} 
          onChange={e => setCompanyName(e.target.value)}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">Establishments</label>
          <input className="form-control" 
          type={"number"} 
          value={establishments} 
          onChange={e => setEstablishments(e.target.value)}/>
        </div>
      </div>

<div className="col-md-12 mb-3">
      {loading === true? Constants.loader : 
        <button onClick={() => NextButton()} className="btn btn-icon btn-icon-end btn-lg btn-primary w-100" type="button">
        <span>Next</span> <i className='fas fa-arrow-right'></i>
      </button>
      }
      </div>
      
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

      
      </>
 

const NoteAndAddress = 
<>
<div className="row g-3">
        <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">State</label>
            <select className="form-control" onChange={e => {setStateOfResidence(e.target.value); GetLGAs(e.target.value)}}>
            <option value={""}>Select State</option>
            {states.map(st =>
            <option value={st}>{st}</option>
            )}
            </select>
</div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">City</label>
            <select onChange={e => setCityOfResidence(e.target.value)} className="form-control">
            <option value={""}>Select City</option>
            {lgas.map(lg =>
            <option value={lg}>{lg}</option>
            )}
            </select>
          </div>
        </div>
      </div>
      <div className='row g-3'>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Landmark/Bus stop</label>
            <input className="form-control" value={landMark} onChange={e => setLandMark(e.target.value)} />
          </div>
          </div>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">How did you know about us?</label>
            <input className="form-control" value={adsSource} onChange={e => setAdsSource(e.target.value)} />
          </div>
          </div>
      </div>
      <div className="row g-3">
        <div className="col-12 mb-3">
        <label className="form-label">Office Address</label>
          <textarea className="form-control" onChange={e => setOfficeAddress(e.target.value)}>{officeAddress}</textarea>
        </div>
      </div>
      <div className="row g-3">
      <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Username</label>
            <input className="form-control" value={userName} onChange={e => setUserName(e.target.value)} />
          </div>
          </div>
      <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Company Logo (Not more than <b>300KB</b>)</label>
            <input type={"file"} 
            className="form-control" value={logo_} 
            onChange={e => {handleFileReadLogo(e); setLogo_(e.target.value)}}
            accept="image/x-png,image/gif,image/jpeg"/>
          </div>
          </div>
          {/* <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Business Certificate (CAC)</label>
            <input type={"file"} className="form-control" 
            value={businessCertificate_} 
            onChange={e => {handleFileReadCert(e); setBusinessCertificate_(e.target.value)}}
            accept="image/x-png,image/gif,image/jpeg" />
          </div>
          </div>
          <div className="col-md-6 mb-3">
          <div className="w-100">
            <label className="form-label">Government ID Card</label>
            <input type={"file"} 
            className="form-control" value={govIDCard_} 
            onChange={e => {handleFileReadIDCard(e); setGovIDCard_(e.target.value)}}
            accept="image/x-png,image/gif,image/jpeg" />
          </div>
          </div> */}
      </div>
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }

      <div className="col-md-12 mb-3">
        <button onClick={() => PrevButton()} className="btn btn-icon btn-icon-end btn-lg btn-warning w-100" type="button">
        <span>Previous</span> <i className='fas fa-arrow-left'></i>
      </button>
      </div>
      
        {loading === true? Constants.loader : 
        <button onClick={() => CheckOutOrder()} className="btn btn-icon btn-icon-end btn-primary w-100" type="button">
        <span>Complete Registration</span> <i className='fas fa-arrow-right'></i>
      </button>
      }
</>

const DeliveryPickUpAddress = 

<div className="col-12 col-lg order-1 order-lg-0">
<div className="card mb-5">
    <div className="card-body">
    <div className="row g-3">
       </div>
        
    {enterUserData? UserData : ''}
    {enterAddress? NoteAndAddress : ''}
                          
    </div>
    </div>
    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
            <div className='row d-flex align-content-center'>
        <div className="px-5">
               <div className="row mb-5">
                  <h2 className="cta-1 mb-0 text-primary">Vendor Registration</h2>
                </div>
                
                <div>

                {DeliveryPickUpAddress} 

                </div>
              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default SignupVendor;