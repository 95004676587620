import React, { useEffect, useState } from 'react';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Header from '../general_components/Header';
import Footer from '../general_components/Footer';

const Vendors = () => {

    const copyToClipboard = (text) => {
        const el = text
        navigator.clipboard.writeText(text)
        alert("link copied")
      }


    const [loading, setLoading] = useState(false);
    
    const [errorMsg, setErrorMsg] = React.useState(null)
    const [search, setSearch] = React.useState(null)

    const [vendorDetails, setVendorDetails] = useState([])
    const SearchVendors = (id) =>{
        setErrorMsg(null)
        setLoading(true)
        if(id === null){
            setLoading(false)
    setErrorMsg("Search box can not be empty")
        }else{
            fetch(process.env.REACT_APP_MAIN_API + id+'/search_vendors')
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) 
                { 
                  setVendorDetails(json.message)
                }
                else{
                    setLoading(false)
                    //setErrorMsg("No result found for "+id+". Please check the ID and try again.")
                }
            })
            .catch((error) => console.error(error))
            .finally(setLoading(false))
        }
    }

    const vendors = () =>{
        setErrorMsg(null)
        setLoading(true)

        fetch(process.env.REACT_APP_MAIN_API +'vendors')
            .then((response) => response.json())
            .then((json) => {
                if (json.status == true) 
                { 
                  setVendorDetails(json.message)
                }
                else{
                    setLoading(false)
                    //setErrorMsg("No result found for "+id+". Please check the ID and try again.")
                }
            })
            .catch((error) => console.error(error))
            .finally(setLoading(false))

    }

    useEffect(() => {
        vendors();        
    }, []);

    const VendorProfileCard =  <>
    {vendorDetails.map(ven =>
    
<div class="card-body card mb-3">
            <div class="d-flex align-items-center flex-column mb-4">
              <div class="d-flex align-items-center flex-column">
              <div class="sw-13 position-relative mb-3">
                        <img src={ven.logo} style={{width: 100, height: 100}}
                        class="img-fluid rounded-xl" alt="thumb" />
                      </div>
               <div class="h5 mb-0">{ven.store_name}</div>
               <div class="text-muted">Status: {ven.is_verified === 0? <span className="text-danger">Not Verified</span> : <span className="text-success"><b>Verified</b></span>}</div>
                <div class="text-muted">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="cs-icon cs-icon-pin me-1"><path d="M3.5 8.5C3.5 -0.191416 16.5 -0.191352 16.5 8.5C16.5 12.6585 12.8256 15.9341 11.0021 17.3037C10.4026 17.754 9.59777 17.754 8.99821 17.3037C7.17467 15.9342 3.5 12.6585 3.5 8.5Z"></path></svg>
                  <span class="align-middle">{ven.state}, {ven.city}</span>
                </div>
              </div>
            </div>
            <div class="nav flex-column" role="tablist">
            <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#projectsTab" role="tab">
                <i className='fas fa-mobile me-3'></i>
                <span class="align-middle">{ven.phone}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-envelope me-3'></i>
              <span class="align-middle">{ven.email}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-compass me-3'></i>
              <span class="align-middle">{ven.store_address}</span>
              </a>
              <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href="#permissionsTab" role="tab">
              <i className='fas fa-landmark me-3'></i>
              <span class="align-middle">{ven.landmark}</span>
              </a>
              <a onClick={() => copyToClipboard(Constants.Website()+ven.username+"/vendor")} data-bs-toggle="tab" 
              class="nav-link px-0 border-bottom border-separator-light" 
              href={Constants.Website()+ven.username+"/vendor"} role="tab">
                <i className='fas fa-link me-3'></i>
                <span class="align-middle">{Constants.Website()+ven.username}/vendor</span>
              </a>
              </div>
          </div>
              )}
</>
    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
                <div>

        {errorMsg !== null ? ShowMessage.ErrorMessage(errorMsg): "" }
        <div className="col-md-12">
        <div class="input-group mb-3">
        <input type="search" className="form-control" 
        onKeyPress={event =>{
            if(event.key === 'Enter'){
                SearchVendors(search)}
            }
        }  
        onChange={e => {setSearch(e.target.value);
        //if(e.target.value.length > 2) SearchVendors(search);
        }} 
        value={search} 
        placeholder="Enter location or username" />
        {loading === true? Constants.loader : 
        <div onClick={() => SearchVendors(search)} class="input-group-text text-white bg-primary">Search </div>
    }
    </div>
    </div>

        {loading === true? Constants.loader : VendorProfileCard }

        </div>
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default Vendors;