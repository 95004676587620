import React, { Component, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Redirect
} from "react-router-dom";

import './App.css';
import ForgotPassword from './auth/ForgotPassword';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Signup from './auth/Signup';
import SignupVendor from './auth/signup_vendor';
import AboutUs from './general_components/AboutUs';
import Cart from './general_components/Cart';
import CheckOut from './general_components/CheckOut';
import ContactUs from './general_components/ContactUs';
import FAQ from './general_components/Faq';

import Home from './general_components/Home';
import OrderDetails from './general_components/OrderDetails';
import PageNotFound from './general_components/PageNotFound';
import Privacy from './general_components/privacy';
import TermsAndConditions from './general_components/terms_and_conditions';
import TrackOrder from './general_components/TrackOrder';
import DryCleaning from './laundry_service/DryCleaningService';
import IronOnly from './laundry_service/IronOnlyService';
import WashAndIron from './laundry_service/WashAndIronService';
import WashOnly from './laundry_service/WashOnlyService';
import CompletedOrdersStaff from './staff_components/completedOrders';
import PendingOrdersStaff from './staff_components/pendingOrders';
import ProcessingOrdersStaff from './staff_components/ProcessingOrders';
import RejectedOrdersStaff from './staff_components/RejectedOrders';
import StaffOrders from './staff_components/staffOrders';
import ChangePassword from './user_components/changePassword';
import CompletedOrders from './user_components/CompletedOrders';
import Dashboard from './user_components/Dashboard';
import PendingOrders from './user_components/PendingOrders';
import ProcessingOrders from './user_components/ProcessingOrders';
import Profile from './user_components/Profile';
import RejectedOrders from './user_components/RejectedOrders';
import ViewOrders from './user_components/ViewOrders';
import PublicVendorDetails from './vendor_components/publicVendorDetails';
import Vendors from './vendor_components/vendors';

function App() {

  return (
   <Router>
  <Routes>
  <Route path="/" element={<Home/>} />
  <Route path="/cart" element={<Cart/>} />
  <Route path="/checkout" element={<CheckOut/>} />
  <Route path="/:order_id/order_details" element={<OrderDetails/>} />
  <Route path="/login" element={<Login/>} />
  <Route path="/logout" element={<Logout/>} />
  <Route path="/signup" element={<Signup/>} />
  <Route path="/signup_vendor" element={<SignupVendor/>} />
  <Route path="/password_reset" element={<ForgotPassword/>} />
  <Route path="/change_password" element={<ChangePassword/>} />
  <Route path="/dashboard" element={<Dashboard/>} />
  <Route path="/more_orders" element={<ViewOrders/>} />
  <Route path="/pending_orders" element={<PendingOrders/>} />
  <Route path="/processing_orders" element={<ProcessingOrders/>} />
  <Route path="/completed_orders" element={<CompletedOrders/>} />
  <Route path="/rejected_orders" element={<RejectedOrders/>} />
  <Route path="/search" element={<TrackOrder/>} />
  <Route path="/contact" element={<ContactUs/>} />
  <Route path="/faq" element={<FAQ/>} />
  <Route path="/terms_and_conditions" element={<TermsAndConditions/>} />
  <Route path="/privacy" element={<Privacy/>} />
  <Route path="/profile" element={<Profile/>} />
  <Route path="/about" element={<AboutUs/>} />

  <Route path="/wash_and_iron" element={<WashAndIron/>} />
  <Route path="/wash_only" element={<WashOnly/>} />
  <Route path="/iron_only" element={<IronOnly/>} />
  <Route path="/dry_cleaning" element={<DryCleaning/>} />


  <Route path="/staff/orders_staff" element={<StaffOrders/>} />
  <Route path="/staff/pending_orders_staff" element={<PendingOrdersStaff/>} />
  <Route path="/staff/processing_orders_staff" element={<ProcessingOrdersStaff/>} />
  <Route path="/staff/completed_orders_staff" element={<CompletedOrdersStaff/>} />
  <Route path="/staff/rejected_orders_staff" element={<RejectedOrdersStaff/>} />

  <Route path="/:username/vendor" element={<PublicVendorDetails/>} />
  <Route path="/vendors" element={<Vendors/>} />


  <Route path="*" element={<PageNotFound/>} />
  </Routes>
  </Router>   
  );
}

export default App;
