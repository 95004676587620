import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import icons from '../constants/icons';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import Footer from './Footer';
import Header from './Header';
import PopularLaundry from './PopularLaundry';
import ShowMessage from '../utilities/ShowMessage';
import SearchVendorsView from '../vendor_components/searchVendorsView';
import { useCookies } from 'react-cookie';

const Home = () => {

    const homeItems = [
        {id: 1, icon: icons.wash_iron, path: 'wash_and_iron', title: 'Wash and Iron', sub_title: 'Wash and iron clothes' },
        {id: 2, icon: icons.wash, path: 'wash_only', title: 'Wash Only', sub_title: 'Wash clothes only' },
        {id: 3, icon: icons.iron, path: 'iron_only', title: 'Iron Only', sub_title: 'Iron clothes only' },
        {id: 4, icon: icons.dry_cleaning, path: 'dry_cleaning', title: 'Dry Cleaning', sub_title: 'Dry Cleaning (Water not used)' }
    ]

    const components = [
        {id: 1, icon: icons.track, path: 'search', title: 'Track', sub_title: 'Track the progress of your order' },
        {id: 2, icon: icons.about, path: 'about', title: 'About', sub_title: 'About eLaundry' },
        {id: 3, icon: icons.faq, path: 'faq', title: 'FAQ', sub_title: 'Fequently Asked Questions' },
        {id: 4, icon: icons.contact, path: 'contact', title: 'Contact Us', sub_title: 'Contact us if you want to get in-touch' }
    ]

    const pageName = "Home";

    useEffect(() => {
        PageBarTitle(pageName)
    });

    const [loading, setLoading] = useState(false);
    const navToPage = (pageLink) => {
      setLoading(true)
return window.location.href=pageLink;
    }

    
    const [errorMsg, setErrorMsg] = React.useState(null)
    const [search, setSearch] = React.useState(null)

    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }

    const GetVendorDetails = () =>{
      fetch(process.env.REACT_APP_MAIN_API + 'get_vendor_details_by_id', options)
      .then((response) => response.json())
      .then((json) => {
          if (json.message !== "Authentication failed!" && !json.status) { 
            //setVendorDetails(json.message)
          //}else{
            //alert(json.message)
          window.location.href="/signup_vendor";
          }
      })
      .catch((error) => console.error(error))
      .finally(console.log("Done"))
    }

useEffect(()=>{
  GetVendorDetails()
},[])
    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>

<div className='mt-5 mb-5'>
<SearchVendorsView />
</div>
  

        {loading === true? Constants.loader : 

       <div className='row'>
        <div className="col-md-12 mb-3">
        <div className='row'>
            {
                homeItems.map(x =>
               
        <div onClick={() => navToPage("/"+x.path) } class="col-md-3 mb-3">
                            <div class="d-flex flex-column card-body bg-white justify-content-center align-items-center">
                               <div class="p-1">
                            <a href="#">
                              <img src={x.icon} style={{with: 55, height: 55}} alt={x.title}/>
                            </a>
                          </div>
                           <div class="p-1">
                                  <font _mstmutation="1"><a href="#" class="body-link" _mstmutation="1">{x.title}</a>
                                  </font>
                                </div>
                                <div class="text-small text-muted text-truncate mb-1">
                                  {x.sub_title}
                                </div> 
                            </div>
                        </div>
                         )

                        }
</div>
                        </div>

                        <div className="col-md-12 mb-3">
                        <div className='row'>
                      { components.map(y =>
               
               <div onClick={() => navToPage("/"+y.path) } class="col-md-3 mb-3">
                <div class="d-flex flex-column card-body bg-white justify-content-center align-items-center">
                               <div class="p-1">
                                   <a href="#">
                                     <img src={y.icon} style={{with: 55, height: 55}} alt={y.title}/>
                                   </a>
                                 </div>
                                       <div class="p-1">
                                         <font _mstmutation="1"><a href="#" class="body-link" _mstmutation="1">{y.title}</a>
                                         </font>
                                       </div>
                                       <div class="text-small text-muted text-truncate mb-1">
                                         {y.sub_title}
                                   </div>
                               </div>
                               </div>
                                )
       
                               }
       </div>
                        </div>

                        </div>
}

        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default Home;