import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import CartServices from '../services/CartServices';
import Constants from '../utilities/Constants';
import PageBarTitle from '../utilities/PageBarTitle';
import ShowMessage from '../utilities/ShowMessage';
import Urls from '../utilities/Urls';

const ForgotPassword = () => {

    const [pageName, setPageName] = React.useState("Forgot Password");
    const [loading, setLoading] = useState(false);
    const [enterCode, setEnterCode] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [emailPhone, setEmailPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [resetCode, setResetCode] = useState(null);
    
    useEffect(() => {
        PageBarTitle(pageName)
    });

const forgotPasswordEntry = () => {
        setErrMsg(null)
        setLoading(true)
        if(password != confirmPassword){
            setErrMsg("Passwords do not match.")
            setLoading(false)
        }
        else{
            forgotPasswordPost()
        }
}
    const forgotPasswordPost = () => {
        setErrMsg(null)
        setLoading(true)
        let user = {
            email: emailPhone, 
            password: password
        }

        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user)
        };

        fetch(process.env.REACT_APP_MAIN_API + 'password_reset', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === true) {
setResetCode(null)
setPassword(null)
setConfirmPassword(null)
                    setEnterCode(true)
                    setLoading(false)
                    /*
                    localStorage.setItem('loginToken', json.loginToken);
                        setErrMsg(null)
                        setLoading(false)
                        if(CartServices.TotalItems() > 0){
                            window.location.replace("/cart")
                        }
                        else
                        {
                            window.location.replace("/")
                        }    
                        */                    
                }
                else {
                    setErrMsg(json.message)
                    setLoading(false)
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const ConfirmCode = () => {
          setLoading(true);
          fetch(process.env.REACT_APP_MAIN_API + resetCode+'/password_reset')
              .then((response) => response.json())
              .then((json) => {
                  //setData(json)
                  if (json.status == true) 
                  { 
                    //setLoading(false);                   
                        window.location.replace("/login")
                    }
                else{
                    setErrMsg(json.message)
                    setLoading(false)
                }
              })
              .catch((error) => console.error(error))
              .finally(() => setLoading(false));
        
    }

    const formOne = <div className='row col-12'>
    <div class="input-group flex-nowrap mb-3">
          <span class="input-group-text" id="addon-wrapping"><i className='fas fa-user'></i></span>
          <input type="text" class="form-control" placeholder="Email or Phone" value={emailPhone} onChange={e => setEmailPhone(e.target.value) } />
      </div>
      <div class="input-group flex-nowrap mb-3">
          <span class="input-group-text" id="addon-wrapping"><i className='fas fa-lock'></i></span>
          <input class="form-control" type="password" value={password} onChange={e => setPassword(e.target.value) } placeholder="New Password" />
      </div>  
      <div class="input-group flex-nowrap mb-3">
          <span class="input-group-text" id="addon-wrapping"><i className='fas fa-lock'></i></span>
          <input class="form-control" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value) } placeholder="Confirm Password" />
      </div>  
      {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
      {loading == true? Constants.loader :                   
          <button onClick={() => forgotPasswordEntry()} className="btn btn-lg btn-primary btn-block">Reset Password</button>
      }
          </div>

          const enterCodeForm = <div className='row col-12'>
              {ShowMessage.SuccessMessage("We have sent a code to "+emailPhone+", to confirm password reset, enter the code below once received")}
          <div class="input-group flex-nowrap mb-3">
                <span class="input-group-text" id="addon-wrapping"><i className='fas fa-lock'></i></span>
                <input type="text" class="form-control" placeholder="Enter Code" value={resetCode} onChange={e => setResetCode(e.target.value) } />
            </div>
            {errMsg !== null ? ShowMessage.ErrorMessage(errMsg): "" }
            {loading == true? Constants.loader :                   
                <button onClick={() => ConfirmCode()} className="btn btn-lg btn-primary btn-block">Proceed</button>
            }
                </div>

    
    return (        
        <>
        <main>
        <div className='container'>
            <div className='row d-flex align-content-center'>
        <div className="px-5">
                <div className="">
                <a href="/">
                    {Constants.logo}
                  </a>
                </div>
                <div className="row mb-5">
                  <h2 className="cta-1 mb-0 text-primary">Greetings!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Remembered password?  <Link to="/login">Login</Link>.</p>
                </div>
                <div>
                {enterCode === false? formOne: enterCodeForm}
                </div>
              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default ForgotPassword;