const GetSeletctedItemsById = (id) => {
    try {
        let cart = localStorage.getItem("cart")
        if(cart !== null){
          var newList = JSON.parse(cart)
          let check = newList.filter(item => item.id === id)
          if (check.length > 0) {
            //update cart for selected item
            let data = check[0]       
            let TotalItems = parseFloat(data.total_items)
            return TotalItems;
          }
          else {
            return 0;
          }
        }else {
          return 0;
        }
               
      } catch (error) {
        console.log(error)
        return 0;
      }
}

export default  GetSeletctedItemsById