import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
//import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import CartServices from '../services/CartServices';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import Urls from '../utilities/Urls';

const Dashboard = () => {

    const [pageName, setPageName] = React.useState("Dashboard");
    const [userId, setUserId] = useState(null)
    const [orderSummary, setOrderSummary] = useState([])
    const [orderCounts, setOrderCounts] = useState([])
    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);
    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }



    useEffect( () => {
       IsLoggedIn();
       
       if(orderSummary == "" || userId === null){
         GetUserDetails()
        }
        GetOrderCounts()
        PageBarTitle(pageName);
    },[userId]);
    
    const IsLoggedIn = async () =>{
      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                  //return true;
              }
              else{
                window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))           
      }

      const GetUserDetails = () => {
        if(orderSummary == ""){
          var options = {  
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token
            }
          }
        fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
          .then((response) => response.json())
          .then((json) => {
              if (json.status == true) { 
                setUserId(json.message[0].user_id)    
         fetch(process.env.REACT_APP_MAIN_API + 'get_five_orders', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status == true) {
                setOrderSummary(json.message)
                }
        })
        .catch((error) => console.error(error))
        .finally(() => console.log("Hello"));    
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done getting details"))
    
}      
}

      const GetOrderCounts = () =>{
        if(orderCounts == "" && userId !== null ){
          var options = {  
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token
            }
          }
        fetch(process.env.REACT_APP_MAIN_API + 'order_summary', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === true) {
                setOrderCounts(json.message[0])
                }
        })
        .catch((error) => console.error(error))
        .finally(() => console.log("Counter done!"));    
              }
      }
    
      const MainContent = 
<div>
      <div class="row g-2">
                <div class="col-6 col-xl-4 sh-19" data-title="More Cards" data-intro="Category card with an icon!" data-step="3">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/more_orders">
                      <i className='fas fa-plus-square'></i>
                      <p class="mt-2 text-body fs-3">{orderCounts.totalOrders}</p>
                      <div class="text-medium fs-3 text-muted">Total Orders</div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-xl-4 sh-19">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/pending_orders">
                    <i className='fas fa-calendar'></i>
                      <p class="fs-3 mt-3 text-body">{orderCounts.pendingOrders}</p>
                      <div class="text-medium fs-3 text-muted">Pending</div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-xl-4 sh-19">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/processing_orders">
                    <i className='fas fa-shipping-fast'></i>
                      <p class="fs-3 mt-3 text-body">{orderCounts.inProgressOrders}</p>
                      <div class="text-medium fs-3 text-muted">In Progress</div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-xl-4 sh-19">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/completed_orders">
                    <i className='fas fa-check-square'></i>
                      <p class="fs-3 mt-3 text-body">{orderCounts.doneOrders}</p>
                      <div class="text-medium fs-3 text-muted">Completed</div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-xl-4 sh-19">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/rejected_orders">
                    <i className='fas fa-times'></i>
                      <p class="fs-3 mt-3 text-body">{orderCounts.rejectedOrders}</p>
                      <div class="text-medium fs-3 text-muted">Rejected</div>
                    </a>
                  </div>
                </div>
                <div class="col-6 col-xl-4 sh-19">
                  <div class="card h-100 hover-scale-up">
                    <a class="card-body text-center" href="/cart">
                    <i className='fas fa-luggage-cart'></i>
                      <p class="fs-3 mt-3 text-body">{CartServices.TotalItems()}</p>
                      <div class="text-medium fs-3 text-muted">Cart</div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="scroll-out mb-5 mt-4">
                <div class="scroll-by-count mb-n2 os-host os-theme-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition" data-count="5" style={{height: "600.008", marginBottom: "-8"}}> 
                
                <div class="d-flex justify-content-between">
                <h2 class="small-title">Last 5 orders</h2>
                <a href="/more_orders" class="btn btn-icon btn-icon-end btn-xs btn-background-alternate p-0 text-small" type="button">
                  <span class="align-bottom">View All</span>
                  </a>
              </div>
              
              {orderSummary.map(os =>
              <a href={"/"+os.order_id+"/order_details"} class="card mb-2">
                    <div class="row g-0 sh-14 sh-md-10">
                      <div class="col">
                        <div class="card-body pt-0 pb-0 h-100">
                          <div class="row g-0 h-100 align-content-center">
                            <div class="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
                              <a href={"/"+os.order_id+"/order_details"}>{os.order_id}</a>
                            </div>
                            <div class="col-12 col-md-3 d-flex align-items-center text-muted text-medium">{Constants.GetOrderStatus(os.status)}</div>
                            <div class="col-12 col-md-3 d-flex align-items-center justify-content-md-end text-muted text-medium">&#x20A6; {NumberFormat(os.total_amount)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
              )}
                  
                </div></div></div>

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
{MainContent}
        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default Dashboard;