const Greetings = () => {
    const time = new Date().getHours();
    let greeting;
    if(time < 12){
        greeting = "Good Morning!"
    }
    else if(time < 16){
        greeting = "Good Day!"
    }
    else{
        greeting = "Good Evening!"
    }
    return greeting;
}

export default Greetings