import React, { Component, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import Header from '../general_components/Header';
import SearchOrder from '../general_components/SearchOrder';
import UserServices from '../services/UserServices';
import Constants from '../utilities/Constants';
import NumberFormat from '../utilities/NumberFormat';
import PageBarTitle from '../utilities/PageBarTitle';
import Urls from '../utilities/Urls';

const RejectedOrdersStaff = () => {

    const [pageName, setPageName] = React.useState("Completed Orders");
    const [isLogged, setIsLoggedIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState(null)
    const [orderSummary, setOrderSummary] = useState([])
    const [cookies, setCookie] = useCookies(["token"]);
    const [token, setToken] = useState(cookies.token);

    var options = {  
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +token
      }
    }
    useEffect(() => {
        IsLoggedIn();
        PageBarTitle(pageName);
        GetUserDetails()
    });
    
    const IsLoggedIn = async () =>{
      fetch(process.env.REACT_APP_MAIN_API +'check_if_logged', options)
      .then((response) => response.json())
          .then((json) => {
               
              if (json.status == true) { 
                  //return true;
              }
              else{
                window.location.replace("/login")
              }
          })
          .catch((error) => console.error(error))
          .finally(console.log("Done"))           
      }

      const GetUserDetails = () =>{
        if(orderSummary == ""){
          setLoading(true)
        fetch(process.env.REACT_APP_MAIN_API + 'get_user_details_by_token', options)
          .then((response) => response.json())
          .then((json) => {
              if (json.status == true) { 
                setUserId(json.message[0].user_id)
                if(userId !== null){
                    
        fetch(process.env.REACT_APP_MAIN_API+'staff/get_rejected_orders', options)
        .then((response) => response.json())
        .then((json) => {
            if (json.status === true) {
                let x = json.message
                
                //let y = x.filter(item => item.status === 0)
                setOrderSummary(x)
                setLoading(false)
                }
                else{
                    setLoading(false)
                }
        })
        .catch((error) => console.error(error))
        .finally();
      }
              }
          })
          .catch((error) => console.error(error))
          .finally(setLoading(false))
        }
      }
    
      const MainContent = 
<div>
      
              <div class="scroll-out mb-5 mt-4">
                <div class="scroll-by-count mb-n2 os-host os-theme-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition" data-count="5" style={{height: "600.008", marginBottom: "-8"}}> 
                
               
              {orderSummary.map(os =>
              <a href={"/"+os.order_id+"/order_details"} class="card mb-2">
                    <div class="row g-0 sh-14 sh-md-10">
                      <div class="col">
                        <div class="card-body pt-0 pb-0 h-100">
                          <div class="row g-0 h-100 align-content-center">
                            <div class="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
                              <a to={"/"+os.order_id+"/order_details"}>{os.order_id}</a>
                            </div>
                            <div class="col-12 col-md-3 d-flex align-items-center text-muted text-medium">{Constants.GetOrderStatus(os.status)}</div>
                            <div class="col-12 col-md-3 d-flex align-items-center justify-content-md-end text-muted text-medium">&#x20A6; {NumberFormat(os.total_amount)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
              )}
                  
                </div></div></div>

    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
        <SearchOrder/>
        {loading? Constants.loader: MainContent}
        </div>        
        </main>
        <Footer/>
        </>
    )
}

export default RejectedOrdersStaff;