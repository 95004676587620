import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../general_components/Footer';
import PageBarTitle from '../utilities/PageBarTitle';
import Header from './Header';

const FAQ = () => {

    const [pageName, setPageName] = React.useState("Frequently Asked Questions");

    useEffect(() => {
        PageBarTitle(pageName);
    });
    
    let faqs = [
        {
            id: 1,
            question: "Who We Are?",
            answer: "elaundry Laundry and Cleaning Services is a business with a team of enthusiastic, experienced and knowledgeable hands in areas ranging from technical dry cleaning & finishing skills to business and management skills required to ensure our services are well differentiated within the industry. The design and implementation of our processes is centered around ensuring exceptionally high standards and a high level of customer satisfaction."
		},
		{
            id: 2,
            question: "Where Are You Located?",
            answer: "Our business is currently limited to Lagos State. However, we have plans to have vendors Nationwide."
		},
		{
            id: 3,
            question: "What Services Do You Offer?",
            answer: "We specialize in Dry Cleaning business with a deep understanding of consumer behavior and needs; consequently, we have over the years designed Services to appeal to different segments of the market and varying needs."
		},
		{
            id: 4,
            question: "What is Your Turn Around Time?",
            answer: "It depends on vendor, but our cycle takes on an average of 3 days between Pick-up and Delivery. Orders are processed at an additional cost for same day delivery."
		},
		{
            id: 5,
            question: "Do I Need to be at the Location for my Pick-up?",
            answer: "No, you don't need to be; you may leave your garments with a representative and give us any useful numbers. We will collect your items, record them on a Waybill or online, a copy of which will be handed to your representative and you will subsequently receive an Order email with details of your Order and Payment Information"
		},
		{
            id: 6,
            question: "How do I know if you can clean a specific type of clothing?",
            answer: "All the types of clothes we clean will be listed when making an order"
		},
		{
            id: 7,
            question: "Can I ask for a Delivery address different from the Pick-up Location?",
            answer: "Yes, you may; simply advise us at pick-up or before your order is due for delivery of your delivery address and this will be updated for you."
		},
		{
            id: 8,
            question: "Do I need to have a Laundry Bag for my items?",
            answer: "No, if you do not have a laundry bag, we will use our laundry bag for pick-up and delivery."
		},
		{
            id: 9,
            question: "Do you have Prices for different Services?",
            answer: "Yes, given our experience, we recognise that people have different budgets and sometimes people simply want to spend less to clean certain low value and everyday items. We have graded Services to allow customer choose which meets their needs and their budget. Simply click on Laundry Service on our App to view all our Service prices."
		},
		{
            id: 10,
            question: "Where Can I Get Your Prices?",
            answer: "Our prices will be made available while making an order"
		},
		{
            id: 11,
            question: "How is Service Delivery?",
            answer: "Service delivery is free of charge for a order that is up to 10,000 NGN, else, service charge attracts 500 NGN"
		},
		{
            id: 12,
            question: "What is the minimum amount of order I can make?",
            answer: "No minimum amount. However, if you are ordering online or you need our pick up and delivery services, the minimum amount of an order is N2,000."
		},
		{
            id: 13,
            question: "What Are Your Business Hours?",
            answer: "Depends on vendor. However, order can be done any time and delivery time starts from when an order is picked up."
		},
		{
            id: 14,
            question: "How Do I Make Payment?",
            answer: "You may make payment online or cash on pick up."
		},
		{
            id: 15,
            question: "How to become a Vendor?",
            answer: "Anyone can become a vendor, but fake vendors account will be deleted and flagged if detected. You can write to us if you are interested."
		},
		{
            id: 16,
            question: "Verified Vendors",
            answer: "Verified vendors ranks more. To get verified, your business must be registered and must have a physical office. Contact us for more information."
		}
		];


const ContentBox = 
<div className="row">
<div class="accordion" id="accordionExample">
  
  {faqs.map(f =>

<div key={f.id} class="accordion-item mb-3">
<h4 class="accordion-header" id={"#collapseTwoCards"+f.id}>
  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwoCards"+f.id} aria-expanded="false" aria-controls={"#collapseTwoCards"+f.id}>
    {f.question}
  </button>
</h4>
<div id={"#collapseTwoCards"+f.id} class="accordion-collapse collapse show" aria-labelledby={"#collapseTwoCards"+f.id} data-bs-parent="#accordionExample">
  <div class="accordion-body">

    {f.answer}

  </div>
</div>
</div>
  )}
</div>
</div>

const MainContent = 

<div className="col-12 col-lg">
        
    {ContentBox}

    </div>

    
    return (        
        <>
        <Header/>
        <main>
        <div className='container'>
        <div className="px-5">
                <div>

                {MainContent} 

              </div>
        </div>   
        </div>     
        </main>
        <Footer/>
        </>
    )
}

export default FAQ;